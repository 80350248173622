import { useEffect, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import { getAllSales } from "../../calls/auths";
import MainLogo from '../../assets/ic.png';
import WatermarkLogo from '../../assets/ic.png';

const Sales = () => {
    const [sales, setSales] = useState([]);
    const [filteredSales, setFilteredSales] = useState([]); // State for filtered sales
    const [sliceCount, setSliceCount] = useState({ from: 0, to: 15 });
    const [selectedSale, setSelectedSale] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchInput, setSearchInput] = useState(""); // State for search input

    const addSlice = () => {
        if (sliceCount.to >= filteredSales.length) {
            setSliceCount({ from: sliceCount.from, to: sliceCount.to });
        } else {
            setSliceCount({ from: sliceCount.to, to: sliceCount.to + 15 });
            document.querySelector('#table-container').scrollTo(0, 0);
        }
    };

    const cutSlice = () => {
        if (sliceCount.from <= 0) {
            setSliceCount({ from: sliceCount.from, to: sliceCount.to });
        } else {
            setSliceCount({ from: sliceCount.from - 15, to: sliceCount.from });
            document.querySelector('#table-container').scrollTo(0, 0);
        }
    };

    const formatPrice = (price) => {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price);
    };

    const formatDateString = (dateStr) => {
        const date = new Date(dateStr);
        const options = {
            year: 'numeric', month: 'numeric', day: 'numeric',
            hour: 'numeric', minute: 'numeric', second: 'numeric',
            hour12: true
        };
        return date.toLocaleString(undefined, options);
    };

    // Fetch all sales from the server
    useEffect(() => {
        async function getSales() {
            const token = localStorage.getItem('tripstore_login');
            const response = await getAllSales(token);
            if (response.status === 200) {
                setSales(response.data);
                setFilteredSales(response.data); // Initialize filtered sales with all sales
            }
        }
        getSales();
    }, []);

    // Filter sales based on search input
    useEffect(() => {
        if (searchInput.trim() === "") {
            setFilteredSales(sales); // If search input is empty, show all sales
        } else {
            const filtered = sales.filter((sale) =>
                sale.orderNumber.toLowerCase().includes(searchInput.toLowerCase()) ||
                sale.payment.details.transactionId.toLowerCase().includes(searchInput.toLowerCase())
            );
            setFilteredSales(filtered);
            setSliceCount({ from: 0, to: 15 }); // Reset slice count when search input changes
        }
    }, [searchInput, sales]);

    const handleSearchChange = (e) => {
        setSearchInput(e.target.value); // Update search input state on change
    };

    const handleViewDetails = (sale) => {
        setSelectedSale(sale);
        setIsModalOpen(true);
    };

    const handlePrint = () => {
        const originalContent = document.body.innerHTML;
        const printContent = document.querySelector('.printable-receipt').innerHTML;
        
        document.body.innerHTML = printContent;
        window.print();
        document.body.innerHTML = originalContent;
    };

    return (
        <>
            <div className="h-[10vh] flex justify-center items-end">
                <div className="w-[95%]">
                    <form action="" className="relative inline-block w-full">
                        <input
                            type="text"
                            className="w-full py-2 px-4 pr-12"
                            placeholder="Search Receipt Number or Transaction ID"
                            value={searchInput}
                            onChange={handleSearchChange}
                        />
                        <button className="absolute right-0 top-[50%] -translate-y-[50%]" type="button">
                            <IoSearch />
                        </button>
                    </form>
                </div>
            </div>

            <div className="h-[70vh] flex justify-center items-start pt-6">
                <div id="table-container" className="w-[95%] max-h-[95%] border-2 border-slate-300 rounded-md overflow-y-auto overflow-x-auto small-scrollbar scrollbar">
                    <table className="w-full relative">
                        <thead className="bg-tertiary text-white sticky">
                            <tr className="text-nowrap *:py-1 *:px-3">
                                <td>S/N</td>
                                <td>Reciept No.</td>
                                <td>Transaction Id</td>
                                <td>Amount</td>
                                <td>Date & Time</td>
                                <td>Attended By</td>
                                <td>Action</td>
                            </tr>
                        </thead>
                        <tbody>
    {filteredSales.length > 0 ? (
        filteredSales
            .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)) // Sort by timestamp descending
            .slice(sliceCount.from, sliceCount.to)
            .map((item, index) => (
                <tr
                    key={index}
                    className=" *:py-1 *:px-3 even:bg-slate-200 cursor-pointer hover:bg-slate-300 active:bg-tertiary active:text-white"
                >
                    <td>{index + 1 + sliceCount.from}</td>
                    <td>{item.orderNumber}</td>
                    <td>{item.payment.details.transactionId}</td>
                    <td>₦{formatPrice(item.total)}</td>
                    <td>{formatDateString(item.timestamp)}</td>
                    <td>{item.salesRep.name}</td>
                    <td>
                        <button onClick={() => handleViewDetails(item)} className="bg-black text-white p-2">View Details</button>
                    </td>
                </tr>
            ))
    ) : (
        <tr>
            <td colSpan={10000} className="py-1 px-3 text-center text-gray-500 font-semibold">
                No Sales Found
            </td>
        </tr>
    )}
</tbody>
                    </table>
                </div>
            </div>

            <div className="h-[10vh] w-[95%] m-auto flex justify-between items-center">
                <button onClick={cutSlice} className="flex items-center gap-4 hover:bg-slate-200">
                    <FaAngleLeft /> <span className="hidden md:inline">Previous</span>
                </button>
                <div className={`${filteredSales.length <= 0 && "hidden"} text-sm font-semibold text-gray-500`}>
                    Showing {1 + sliceCount.from} to {sliceCount.to >= filteredSales.length ? filteredSales.length : sliceCount.to} of {filteredSales.length}
                </div>
                <button onClick={addSlice} className="flex items-center gap-4 hover:bg-slate-200">
                    <span className="hidden md:inline">Next</span> <FaAngleRight />
                </button>
            </div>

            {/* Modal for viewing sale details */}
            {isModalOpen && selectedSale && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-md max-w-lg w-full">
                        <div className="p-8 printable-receipt">
                            <div className="relative bg-white shadow-lg rounded-lg mx-auto my-8" style={{ width: '80mm' }}>
                                {/* Watermark Logo */}
                                <img src={WatermarkLogo} className="absolute inset-0 opacity-10 w-full h-full object-contain" alt="Watermark" />
                                
                                {/* Main Logo */}
                                <div className="text-center mb-4 pt-4">
                                    <img src={MainLogo} className="w-32 mx-auto" alt="Main Logo" />
                                </div>
                                
                                {/* Receipt Content */}
                                <div className="relative px-4">
                                    <h2 className="text-xl font-bold mb-2 text-center">Order Receipt</h2>
                                    <p className="text-center mb-4">Thank you for your purchase!</p>
                                    
                                    <div className="mb-4">
                                        <p><span className="font-semibold">Order Number:</span> {selectedSale.orderNumber}</p>
                                        <p><span className="font-semibold">Order Date:</span> {formatDateString(selectedSale.timestamp)}</p>
                                        <p><span className="font-semibold">Sales Rep Name:</span> {selectedSale.salesRep.name}</p>
                                    </div>

                                    <div className="mb-4">
                                        <h3 className="font-semibold mb-2">Items Purchased:</h3>
                                        <table className="w-full text-left border-collapse">
                                            <thead>
                                                <tr>
                                                    <th className="border-b py-1">Item</th>
                                                    <th className="border-b py-1">Qty</th>
                                                    <th className="border-b py-1">Price</th>
                                                    <th className="border-b py-1">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedSale.items.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="border-b py-1">{item.product}</td>
                                                        <td className="border-b py-1">{item.quantity}</td>
                                                        <td className="border-b py-1">&#8358;{formatPrice(item.price)}</td>
                                                        <td className="border-b py-1">&#8358;{formatPrice(item.price * item.quantity)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="mt-4">
                                        <p className="font-semibold text-right">Subtotal: &#8358;{formatPrice(selectedSale.subtotal)}</p>
                                        <p className="font-semibold text-right">VAT 7.5%: &#8358;{formatPrice(selectedSale.tax)}</p>
                                        <p className="font-semibold text-right">Discount: &#8358;{formatPrice(selectedSale.discountAmount)}</p>
                                        <p className="font-semibold text-right">Total: &#8358;{formatPrice(selectedSale.total)}</p>
                                    </div>
                                </div>
                                
                                {/* Footer */}
                                <div className="text-center mt-4 pb-4">
                                    <p className="text-sm">If you have any questions about your order, please contact us at support@tripstore.ng</p>
                                    <p className="text-sm mt-2">Thank you for shopping with us!</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <button onClick={() => setIsModalOpen(false)} className="mr-4 bg-gray-300 shadow-2xl hover:bg-red-800 hover:text-white">Close</button>
                            <button onClick={handlePrint} className="bg-black text-white">Print</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Sales;