import { NavLink } from "react-router-dom"
import SideNav from "../SideNav"
import { RxDashboard } from "react-icons/rx";
import { IoSettingsOutline, IoBagHandleOutline } from "react-icons/io5";
import { BsBagPlus } from "react-icons/bs";
import { GrUserSettings } from "react-icons/gr";

const VendorNavs = ({ expandedNav, setExpandedNav }) => {

    return (
        <SideNav expandedNav={expandedNav} setExpandedNav={setExpandedNav}>
            <div className=" text-gray-500 font-semibold mb-3 mt-6 uppercase text-lg">MENU</div>
            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : ' text-gray-800')} to={'/vendor/dashboard'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                    <RxDashboard />
                    <span>Dashboard</span>
                </div>
            </NavLink>
            

            <div className=" text-gray-500 font-semibold mb-3 mt-6 uppercase text-lg">PRODUCT</div>
            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : ' text-gray-800')} to={'/vendor/add_product'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                    <BsBagPlus />
                    <span>Add Products</span>
                </div>
            </NavLink>

            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : ' text-gray-800')} to={'/vendor/inventory'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                    <IoBagHandleOutline />
                    <span>Inventory</span>
                </div>
            </NavLink>

            <div className=" text-gray-500 font-semibold mb-3 mt-6 uppercase text-lg">Settings</div>
            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : ' text-gray-800')} to={'/vendor/settings'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                <GrUserSettings />
                    <span>Profile</span>
                </div>
            </NavLink>
        </SideNav>
    )
}

export default VendorNavs