import { NavLink } from "react-router-dom"
import SideNav from "../SideNav"
import { IoSettingsOutline, IoBagHandleOutline } from "react-icons/io5";
import { BsBagPlus } from "react-icons/bs";
import { CgSize } from "react-icons/cg";
import { BsTicket } from "react-icons/bs";
import {  TbSettingsCode, TbCategoryPlus } from "react-icons/tb";
import { GrUserSettings } from "react-icons/gr";
import { SiPowerpages } from "react-icons/si";
import { LuUsers } from "react-icons/lu";

const SupervisorNavs = ({ expandedNav, setExpandedNav }) => {

    return (
        <SideNav expandedNav={expandedNav} setExpandedNav={setExpandedNav}>           

            <div className=" text-gray-500 font-semibold mb-3 mt-6 uppercase text-lg">PRODUCT</div>
            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : ' text-gray-800')} to={'/supervisor/add_category'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                    <TbCategoryPlus />
                    <span>Categories</span>
                </div>
            </NavLink>
            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : ' text-gray-800')} to={'/supervisor/sizes'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                    <CgSize />
                    <span>Sizes</span>
                </div>
            </NavLink>
            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : ' text-gray-800')} to={'/supervisor/add_product'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                    <BsBagPlus />
                    <span>Add Products</span>
                </div>
            </NavLink>
            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : ' text-gray-800')} to={'/supervisor/coupons'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                    <BsTicket />
                    <span>Coupons</span>
                </div>
            </NavLink>
            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : ' text-gray-800')} to={'/supervisor/inventory'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                    <IoBagHandleOutline />
                    <span>Inventory</span>
                </div>
            </NavLink>

            <div className=" text-gray-500 font-semibold mb-3 mt-6 uppercase text-lg">Settings</div>
            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : ' text-gray-800')} to={'/supervisor/promoter'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                <LuUsers />
                    <span>Promoter</span>
                </div>
            </NavLink>
            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : ' text-gray-800')} to={'/supervisor/adverts'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                <TbSettingsCode />
                    <span>Promo Banner</span>
                </div>
            </NavLink>
            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : ' text-gray-800')} to={'/supervisor/pages'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                <SiPowerpages />
                    <span>Pages</span>
                </div>
            </NavLink>
            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : ' text-gray-800')} to={'/supervisor/backends'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                <TbSettingsCode />
                    <span>UI/Settings</span>
                </div>
            </NavLink>
            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : ' text-gray-800')} to={'/supervisor/fashion_categories'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                    <IoSettingsOutline />
                    <span>Fashion Categories</span>
                </div>
            </NavLink>
            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : ' text-gray-800')} to={'/supervisor/settings'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                <GrUserSettings />
                    <span>Profile</span>
                </div>
            </NavLink>
        </SideNav>
    )
}

export default SupervisorNavs