import { BrowserRouter, Route, Routes } from "react-router-dom"
import User from "./User"
import Home from "./screens/Test"
import SalesRep from "./SalesRep"
import Admin from "./Admin"
import Supervisor from "./Supervisor"
import Login from "./Login"
import Zaddy from "./Zaddy"
import AppContextProvider from "./context/AppContext"
import AdminReg from "./screens/admin/AdminReg"
import Product from "./screens/Product"
import Cart from "./screens/Cart"
import Checkout from "./screens/Checkout"
import Wishlist from "./screens/Wishlist"
import Category from "./screens/Category"
import Search from "./screens/Search"
import Promoter from "./screens/Promoters"
import Receipt from "./screens/Receipt"
import About from "./screens/About"
import Contact from "./screens/Contact"
import Faq from "./screens/Faq"
import Payment from "./screens/Payment"
import Policy from "./screens/Policy"
import Shipping from "./screens/Shipping"
import Shopping from "./screens/Shopping"
import Support from "./screens/Support"
import Terms from "./screens/Terms"
import NotFound from "./screens/NotFound" // Import the new NotFound component
import ForgotPassword from "./screens/ForgotPassword" // Import the new ForgotPassword component
import ResetPassword from "./screens/ResetPassword"
import VendorLogin from "./VendorLogin"
import Vendor from "./Vendor"

const App = () => {

  return (
    <BrowserRouter>

      <AppContextProvider>

        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<VendorLogin />} path="/vendors" />
          <Route element={<Vendor />} path="/vendor/*" />
          <Route element={<Product />} path="/product/:productId/:naming" />
          <Route element={<Zaddy />} path="/zaddypay/:id" />
          <Route element={<Cart />} path="/cart" />
          <Route element={<Category />} path="/category" />
          <Route element={<Search />} path="/search" /> 
          <Route element={<Receipt />} path="/receipt" />
          <Route element={<Checkout />} path="/checkout" />
          <Route element={<Wishlist />} path="/wishlist" />
          <Route element={<Login />} path="/login" />
          <Route element={<About />} path="/about" />
          <Route element={<Contact />} path="/contact" />
          <Route element={<Faq />} path="/faq" />
          <Route element={<Promoter />} path="/promoter" />
          <Route element={<Payment />} path="/payment" />
          <Route element={<Policy />} path="/policy" />
          <Route element={<Shipping />} path="/shipping" />
          <Route element={<Shopping />} path="/shopping" />
          <Route element={<Support />} path="/support" />
          <Route element={<Terms />} path="/terms" />
          <Route element={<AdminReg />} path="/admin_reg" />
          <Route element={<User />} path="/user/*" />
          <Route element={<SalesRep />} path="/sales_rep/*" />
          <Route element={<Admin />} path="/admin/*" />
          <Route element={<Supervisor />} path="/supervisor/*" />
          <Route element={<ResetPassword />} path="/reset-password" />
          <Route element={<ForgotPassword />} path="/forgot-password" />
          <Route element={<NotFound />} path="*" /> {/* Add this Route as the last one */}
        </Routes>

      </AppContextProvider>

    </BrowserRouter>
  )
}

export default App