import { useContext, useState, useEffect } from "react";
import { FaCcVisa, FaCcMastercard, FaCcPaypal, FaCcDiscover } from "react-icons/fa";
import { AppContext } from "../../context/AppContext";
import { usePaystackPayment } from 'react-paystack';
import { activateCoupon, checkPointDet, createOrder, createZaddyOrder, getPaystackKey, getUserInfo } from "../../calls/auths";
import { useNavigate } from "react-router-dom";

const CheckoutMain = () => {
    const Navigate = useNavigate();

    const { setUser, setLoggedIn, productInformation, user } = useContext(AppContext);
    const localStorageCart = JSON.parse(localStorage.getItem('cart')) || [];
    const paymentMethod = [
        { title: "Credit Card", info: "Pay with your credit card on Tripstore" },
        { title: "PayPal", info: "Pay via PayPal; you can pay with your credit card if you don’t have a PayPal account." }
    ];
    const [disableZaddyButton, setDisableZaddyButton] = useState(false);
    const [couponCode, setCouponCode] = useState('');
    const [promoDiscount, setPromoDiscount] = useState(null);
    const [promoName, setPromoName] = useState(null);
    const [appliedCoupons, setAppliedCoupons] = useState([]);
    const [pointDets, setPointsDet] = useState(null);

    const shipping = JSON.parse(localStorage.getItem("selectedShipping")) || { shipping: { title: "", price: 0 } };

    const [currentPaymentMethod, setCurrentPaymentMethod] = useState({ index: 0, paymentMethod: paymentMethod[0] });
    
    const [formData, setFormData] = useState({
        paymentMethod: currentPaymentMethod.paymentMethod.title,
        shippingTitle: shipping.shipping?.title || "",
        shippingPrice: shipping.shipping?.price || 0,
        items: localStorageCart,
        first_name: user?.firstname || "",
        last_name: user?.lastname || "",
        street_address: user?.address || "",
        town_city: user?.city || "",
        state: user?.state || "",
        postcode_zip: "",
        phone: user?.phone_number || "",
        email: user?.email || "",
        discount: promoDiscount,
        create_acct: false,
        ship_diff: false,
        note: ""
    });

    useEffect(() => {
        if (user) {
            setFormData((prevData) => ({
                ...prevData,
                first_name: user?.firstname || "",
                last_name: user?.lastname || "",
                street_address: user?.address || "",
                town_city: user?.city || "",
                state: user?.state || "",
                phone: user?.phone_number || "",
                email: user?.email || ""
            }));
        }
    }, [user]);

    const Coupon = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const requiredFields = ["first_name", "last_name", "street_address", "town_city", "state", "postcode_zip", "phone", "email"];
        for (const field of requiredFields) {
            if (!formData[field]) {
                alert(`Please fill in the required field: ${field.replace('_', ' ')}`);
                return;
            }
        }

        initializePayment({onSuccess, onClose})
    };

    const changeCurrentPaymentMethod = (index) => {
        setCurrentPaymentMethod({ index: index, paymentMethod: paymentMethod[index] });
    };

    const calculateSubtotal = () => {
        return productInformation.reduce((total, item) => {
            const discount = item.product.discount ? (item.product.price * item.product.discount) / 100 : 0;
            const discountedPrice = item.product.price - discount;
            return total + discountedPrice * item.quantity;
        }, 0);
    };

    const calculateTotal = () => {
        const subtotal = calculateSubtotal();
        const vat = subtotal * 0.075;
        const shippingCost = shipping.shipping.price;
        const discount = promoDiscount ? (subtotal * promoDiscount) / 100 : 0;
        return subtotal + vat + shippingCost - discount;
    };

    let currentShipping = shipping.shipping.price;
    const subtotal = calculateSubtotal();
    const totalInKobo = parseInt(calculateTotal() * 100).toFixed(2); // Convert to kobo

    const config = {
        reference: (new Date()).getTime().toString(),
        email: formData.email,
        amount: totalInKobo, // Amount in kobo //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
        publicKey: getPaystackKey(),
        metadata: {
            custom_fields: [
                {
                    product: 'product name',
                }
                // To pass extra metadata, add an object with the same fields as above
            ]
        }
    };

    async function handleSuccess() {
        try {
            // Create a new formData object with the discount included
            const updatedFormData = {
                ...formData,
                couponDiscount: promoDiscount,
                couponName: promoName
            };
            const response = await createOrder(updatedFormData);
            if (response.status === 200) {
                const { order, token } = response.data;
                if (token) {
                    window.localStorage.setItem("tripstore_login", token);
                    const loginSession = window.localStorage.getItem("tripstore_login");
                    const person = await getUserInfo(loginSession);
                    setUser(person.data);
                    setLoggedIn(true);
                }
                localStorage.removeItem('cart'); // Clear the cart after order creation
                window.location.href = `/user/order`; // Reload the whole page to the order route
            } else {
                alert(`Order creation failed: ${response.data.message}`);
            }
        } catch (error) {
            console.error('Error creating order:', error);
            alert('Error creating order, please try again later.');
        }
    }

    async function handleZaddyOrder(e) {
        e.preventDefault();

        const requiredFields = ["first_name", "last_name", "street_address", "town_city", "state", "postcode_zip", "phone", "email"];
        for (const field of requiredFields) {
            if (!formData[field]) {
                alert(`Please fill in the required field: ${field.replace('_', ' ')}`);
                return;
            }
        }

        try {
            setDisableZaddyButton(!disableZaddyButton);
            const response = await createZaddyOrder(formData);
            if (response.status === 200) {
                const { order, token } = response.data;
                if (token) {
                    window.localStorage.setItem("tripstore_login", token);
                    const loginSession = window.localStorage.getItem("tripstore_login");
                    const person = await getUserInfo(loginSession);
                    setUser(person.data);
                    setLoggedIn(true);
                }
                setDisableZaddyButton(!disableZaddyButton);
                localStorage.removeItem('cart'); // Clear the cart after order creation
                Navigate(`/user/zaddylist`);
                // Optionally, navigate to a success page or order summary
            } else {
                alert(`Order creation failed: ${response.data.message}`);
            }
        } catch (error) {
            console.error('Error creating order:', error);
            alert('Error creating order, please try again later.');
        }
    }
    
    const onSuccess = (reference) => {
        // Implementation for whatever you want to do with reference and after success call.
        handleSuccess()
    };
  
    // you can call this function anything
    const onClose = () => {
      // implementation for  whatever you want to do when the Paystack dialog closed.
    }

    const initializePayment = usePaystackPayment(config);

    const handleCouponSubmit = async (e) => {
        e.preventDefault();

        if (appliedCoupons.includes(couponCode)) {
            alert("This coupon code has already been applied.");
            return;
        }

        const response = await activateCoupon(couponCode);
        if (response.status === 200) {
            setPromoDiscount(response.data.discount);
            setPromoName(response.data.name);
            setAppliedCoupons([...appliedCoupons, couponCode]);
        } else {
            alert("Invalid coupon code.");
        }
    };

    const handlePointApply = async (e) => {
        e.preventDefault();
        if (!user) {
            alert("Not user");
        }
        try {
            const response = await checkPointDet( user.phone_number, calculateTotal());
            if (response.data) {
                setPointsDet(response.data.data);
                setPromoDiscount(response.data.data.pointsUsed)
                alert("Point Processed successfully");
            } else {
                alert('Unable to process Points');
            }
        } catch (error) {
            console.error('Error activating points:', error);
            alert('There was an error applying the points.');          
        }
    }

    const handleCouponInputChange = (e) => {
        setCouponCode(e.target.value);
    }

    

    return (
        <div className="py-4 container">
            <div className="flex flex-col lg:flex-row items-start gap-4">
                <div className="w-full lg:flex-1">
                    <div className="mb-2">Billing Details</div>
                    <form className="w-full flex flex-col gap-4" onSubmit={handleSubmit}>
                        <div className="flex flex-col sm:flex-row *:w-full gap-4">
                            <div className="*:block">
                                <label htmlFor="first-name" className="text-sm font-semibold text-gray-500">First Name *</label>
                                <input
                                    type="text"
                                    name="first_name"
                                    id="first-name"
                                    value={formData.first_name}
                                    onChange={Coupon}
                                    className="w-full border-[1px] border-slate-300 rounded-sm focus:border-primary bg-slate-100 focus:bg-transparent"
                                />
                            </div>
                            <div className="*:block">
                                <label htmlFor="last-name" className="text-sm font-semibold text-gray-500">Last Name *</label>
                                <input
                                    type="text"
                                    name="last_name"
                                    id="last-name"
                                    value={formData.last_name}
                                    onChange={Coupon}
                                    className="w-full border-[1px] border-slate-300 rounded-sm focus:border-primary bg-slate-100 focus:bg-transparent"
                                />
                            </div>
                        </div>
                        <div className="*:block">
                            <label htmlFor="street-address" className="text-sm font-semibold text-gray-500">Street address *</label>
                            <input
                                type="text"
                                name="street_address"
                                id="street-address"
                                value={formData.street_address}
                                onChange={Coupon}
                                placeholder="House number and Street name"
                                className="w-full border-[1px] border-slate-300 rounded-sm focus:border-primary bg-slate-100 focus:bg-transparent"
                            />
                        </div>
                        <div className="flex flex-col sm:flex-row *:w-full gap-4">
                            <div className="*:block">
                                <label htmlFor="town-city" className="text-sm font-semibold text-gray-500">Town / City *</label>
                                <input
                                    type="text"
                                    name="town_city"
                                    id="town-city"
                                    value={formData.town_city}
                                    onChange={Coupon}
                                    className="w-full border-[1px] border-slate-300 rounded-sm focus:border-primary bg-slate-100 focus:bg-transparent"
                                />
                            </div>
                            <div className="*:block">
                                <label htmlFor="state" className="text-sm font-semibold text-gray-500">State *</label>
                                <input
                                    type="text"
                                    name="state"
                                    id="state"
                                    value={formData.state}
                                    onChange={Coupon}
                                    className="w-full border-[1px] border-slate-300 rounded-sm focus:border-primary bg-slate-100 focus:bg-transparent"
                                />
                            </div>
                        </div>
                        <div className="flex flex-col sm:flex-row *:w-full gap-4">
                            <div className="*:block">
                                <label htmlFor="postcode-zip" className="text-sm font-semibold text-gray-500">Postcode / ZIP *</label>
                                <input
                                    type="number"
                                    name="postcode_zip"
                                    id="postcode-zip"
                                    value={formData.postcode_zip}
                                    onChange={Coupon}
                                    className="w-full border-[1px] border-slate-300 rounded-sm focus:border-primary bg-slate-100 focus:bg-transparent"
                                />
                            </div>
                            <div className="*:block">
                                <label htmlFor="phone" className="text-sm font-semibold text-gray-500">Phone *</label>
                                <input
                                    type="tel"
                                    name="phone"
                                    id="phone"
                                    value={formData.phone}
                                    onChange={Coupon}
                                    className="w-full border-[1px] border-slate-300 rounded-sm focus:border-primary bg-slate-100 focus:bg-transparent"
                                />
                            </div>
                        </div>
                        <div className="*:block">
                            <label htmlFor="email" className="text-sm font-semibold text-gray-500">Email address *</label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                value={formData.email}
                                onChange={Coupon}
                                className="w-full border-[1px] border-slate-300 rounded-sm focus:border-primary bg-slate-100 focus:bg-transparent"
                            />
                        </div>
                        <div className="*:block">
                            <label htmlFor="note" className="text-sm font-semibold text-gray-500">Order notes (optional)</label>
                            <textarea
                                name="note"
                                id="note"
                                value={formData.note}
                                onChange={Coupon}
                                placeholder=" Notes about your order, e.g. special notes for delivery"
                                className="w-full border-[1px] border-slate-300 rounded-sm focus:border-primary bg-slate-100 focus:bg-transparent"
                            ></textarea>
                        </div>
                        <button type="submit" className="hidden"></button>
                    </form>
                </div>
                <div className="w-full lg:w-[300px] rounded-sm border-[1px] border-dashed border-slate-300 bg-slate-100 p-2">
                    <div className="font-semibold">Your Order</div>
                    <div className="w-full h-[1px] my-2 bg-slate-400"></div>
                    <div className="flex items-center justify-between">
                        <div className="">Product</div>
                        <div className="">Total</div>
                    </div>
                    <div className="divide-y-[1px] divide-slate-300 text-gray-600">
                        {productInformation.map((item, index) => (
                            <div key={index} className="flex items-center gap-4 py-2">
                                <div className="flex-1 text-sm">{item.product.name} ({item.product.color} colour) x {item.quantity} {item.product.discount && <span className="text-green-500">({item.product.discount}% OFF!)</span>}</div>
                                <div className="">
                                    {item.product.discount ? (
                                        <>
                                        <span style={{ textDecoration: 'line-through' }} className="text-red-500">
                                            ₦{(item.product.price * item.quantity).toLocaleString()}
                                        </span>
                                        &nbsp;₦{((item.product.price * (1 - item.product.discount / 100)) * item.quantity).toLocaleString()}
                                        </>
                                    ) : (
                                        `₦${(item.product.price * item.quantity).toLocaleString()}`
                                    )}
                                </div>


                            </div>
                        ))}
                    </div>
                    <p>{promoDiscount && <p className="text-green-500">COUPON CODE DISCOUNt: {promoDiscount}% OFF!</p>}</p>
                    <div className="w-full h-[1px] my-2 bg-slate-300"></div>
                    <div className="flex items-center justify-between">
                        <div className="">Subtotal:</div>
                        <div className="">&#8358;{(subtotal - (subtotal * promoDiscount) / 100).toFixed(2)}</div>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="">VAT 7.5%:</div>
                        <div className="">&#8358;{(subtotal * 0.075).toFixed(2)}</div>
                    </div>
                    <div className="w-full h-[1px] my-2 bg-slate-300"></div>
                    {productInformation.length > 0 && 
                    <div className="flex items-center justify-between text-gray-600">
                        <div className="">Shipping ({shipping.shipping.title}):</div>
                        <div className="">&#8358;{shipping.shipping.price.toLocaleString()} </div>
                    </div>
                    }
                    
                    {promoDiscount && (
                        <div className="flex justify-between items-center border-b pb-2 mb-2">
                            <span className="text-sm font-semibold">Discount</span>
                            <span className="text-sm font-semibold">-${((subtotal * promoDiscount) / 100).toFixed(2)}</span>
                        </div>
                    )}
                    <div className="w-full h-[1px] my-2 bg-slate-300"></div>
                    {productInformation.length > 0 && 
                        <>
                            <div className="flex items-center justify-between font-semibold text-primary">
                                <div className="">Total:</div>
                                <div className="">&#8358;{calculateTotal().toFixed(2)}</div>
                            </div>
                            <form onSubmit={handleCouponSubmit}>
                                <div className="border border-black rounded-lg p-3 mt-3">
                                    <p className="text-center">Coupon</p>
                                    <input value={couponCode} onChange={handleCouponInputChange} type="text" placeholder="Coupon Code" className="mt-1 w-full rounded-md bg-white border-gray-200 shadow-sm sm:text-sm" />
                                    <button className="btn bg-primary w-full py-2 rounded-sm text-white uppercase hover:bg-slate-800 mt-4">Apply</button>
                                </div>
                            </form>

                            <form onSubmit={handlePointApply}>
                                <div className="border border-black rounded-lg p-3 mt-3">
                                    {/* <p className="text-center">Apply Points</p> */}

                                    {/* <input value={couponCode} onChange={handleCouponInputChange} type="text" placeholder="Coupon Code" className="mt-1 w-full rounded-md bg-white border-gray-200 shadow-sm sm:text-sm" /> */}
                                    
                                    <button className="btn bg-primary w-full py-2 rounded-sm text-white uppercase hover:bg-slate-800 mt-4">Apply Points</button>
                                </div>
                            </form>
                        </>
                    }
                    <div className="mt-4">
                        {paymentMethod.map((item, index) => (
                            <div key={index} className="">
                                <div className="flex items-center justify-between my-2">
                                    <div onClick={() => changeCurrentPaymentMethod(index)} className="flex items-center gap-2 group cursor-pointer">
                                        <div className={`${currentPaymentMethod.index === index ? 'border-primary' : 'border-slate-300 group-hover:border-primary'} w-[20px] aspect-square rounded-full border-[1px] flex justify-center items-center`}>
                                            {currentPaymentMethod.index === index && (
                                                <div className="w-[10px] aspect-square rounded-full bg-primary"></div>
                                            )}
                                        </div>
                                        <div className="capitalize">{item.title}</div>
                                    </div>
                                    {index === 0 && (
                                        <div className="flex items-center gap-2">
                                            <FaCcVisa size="24px" color="#00509d" />
                                            <FaCcMastercard size="24px" color="#cba40b" />
                                            <FaCcDiscover size="24px" color="#E15A22" />
                                            <FaCcPaypal size="24px" color="#1f202f" />
                                        </div>
                                    )}
                                </div>
                                <div className={`${currentPaymentMethod.index === index ? 'block' : 'hidden'} text-sm ml-6 pl-3 border-l-[1px] border-slate-300 text-gray-500`}>
                                    {item.info}
                                </div>
                            </div>
                        ))}
                        <div className="w-full h-[1px] my-2 bg-slate-300"></div>
                        <div className="text-sm font-medium text-gray-500">
                            Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.
                        </div>
                        {productInformation.length > 0 && (
                            <>
                                <button 
                                onClick={handleSubmit} 
                                className="btn bg-primary w-full py-2 rounded-sm text-white uppercase hover:bg-slate-800 mt-4"
                                >
                                Place Order
                                </button>
                                
                                {disableZaddyButton ? (
                                <p>Loading Zaddy Link...</p>
                                ) : (
                                <button 
                                    onClick={handleZaddyOrder} 
                                    className="btn w-full py-2 rounded-sm text-white uppercase mt-4 zaddy-pay-btn"
                                >
                                    Zaddy Pay
                                </button>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckoutMain;
