import React, { useEffect, useState } from "react";
import Modal from 'react-modal';
import { getAllVendors, updateVendors } from "../../calls/auths";
import Profile from "../../components/VendorProfile";

// const statuses = ["Active", "Inactive", "Pending", "Suspended"];
Modal.setAppElement('#root');
const statuses = ['active', 'suspended', 'banned', 'pending'];

const Vendors = () => {
  const [vendors, setVendors] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewVendor, setViewVendor] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [statusMenuOpen, setStatusMenuOpen] = useState(false);

  const itemsPerPage = 10; // Number of items per page

  const handleVendorView = (vendor) => {
    setViewVendor(vendor);
    setIsModalOpen(true);
  }

  useEffect(() => {
    const fetchVendors = async () => {
      const result = await getAllVendors();
      if (result.status === 200) {
        setVendors(result.data);
      } else {
        console.error("Error fetching vendors:", result.data);
      }
    };

    fetchVendors();
  }, []);

  const handleStatusClick = (vendorId) => {
    setSelectedVendorId(vendorId);
    setStatusMenuOpen(!statusMenuOpen);
  };

  const handleStatusChange = async (vendorId, newStatus) => {
    try {
      const updatedVendor = vendors.find((vendor) => vendor._id === vendorId);
      if (!updatedVendor) return;

      // Make the PUT API call to update the vendor's status
      
      const token = window.localStorage.getItem("tripstore_login");
      const response = await updateVendors({ ...updatedVendor, status: newStatus },vendorId, token)
      // updateVendorStatus(vendorId, { ...updatedVendor, status: newStatus });

      if (response.status === 200) {
        // Update the local state with the new status
        setVendors((prevVendors) =>
          prevVendors.map((vendor) =>
            vendor._id === vendorId ? { ...vendor, status: newStatus } : vendor
          )
        );
        console.log("Status updated successfully");
      } else {
        console.error("Failed to update status", response.data);
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }

    setStatusMenuOpen(false);
  };

  const handleNextPage = () => {
    if (currentPage * itemsPerPage < vendors.length) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const paginatedVendors = vendors.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="overflow-x-auto p-4">
      <table className="min-w-full bg-white border border-gray-300">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-4 py-2 text-left">S/N</th>
            <th className="px-4 py-2 text-left">Name</th>
            <th className="px-4 py-2 text-left">Business Address</th>
            <th className="px-4 py-2 text-left">Date Onboarded</th>
            <th className="px-4 py-2 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          {paginatedVendors.map((vendor, index) => (
            <tr key={vendor._id} className="border-t">
              <td className="px-4 py-2">{index + 1 + (currentPage - 1) * itemsPerPage}</td>
              <td className="px-4 py-2 flex items-center space-x-4">
                <img
                  src="https://avatar.iran.liara.run/public" // Placeholder avatar
                  alt="Avatar"
                  className="w-10 h-10 rounded-full"
                />
                <div>
                  <div className="font-bold">{`${vendor.firstname} ${vendor.lastname}`}</div>
                  <div className="text-sm text-gray-500">{vendor.email}</div>
                </div>
              </td>
              <td className="px-4 py-2">{vendor.businessAddress}</td>
              <td className="px-4 py-2">
                {new Date(vendor.createdAt).toLocaleDateString()}
              </td>
              <td className="px-4 py-2 flex space-x-2">
                <button
                  onClick={() => handleVendorView(vendor)}
                  className="px-4 py-2 bg-slate-800 text-white text-sm rounded-md hover:bg-slate-600"
                >
                  View
                </button>
                <div className="relative">
                  <button
                    onClick={() => handleStatusClick(vendor._id)}
                    className="px-4 py-2 bg-primary text-white text-sm rounded-md hover:bg-gray-600"
                  >
                    {vendor.status}
                  </button>
                  {statusMenuOpen && selectedVendorId === vendor._id && (
                    <div className="absolute top-10 left-0 bg-white border border-gray-300 rounded-md shadow-lg z-10">
                      {statuses.map((status) => (
                        <button
                          key={status}
                          onClick={() => handleStatusChange(vendor._id, status.toLowerCase())}
                          className={`block px-4 py-2 text-left text-sm hover:bg-gray-100 w-full ${
                            status.toLowerCase() === vendor.status.toLowerCase()
                              ? "font-bold"
                              : ""
                          }`}
                        >
                          {status}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className={`px-4 py-2 bg-gray-200 text-gray-700 rounded-md ${
            currentPage === 1 ? "cursor-not-allowed opacity-50" : "hover:bg-gray-300"
          }`}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {Math.ceil(vendors.length / itemsPerPage)}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage * itemsPerPage >= vendors.length}
          className={`px-4 py-2 bg-gray-200 text-gray-700 rounded-md ${
            currentPage * itemsPerPage >= vendors.length
              ? "cursor-not-allowed opacity-50"
              : "hover:bg-gray-300"
          }`}
        >
          Next
        </button>
      </div>


      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(!isModalOpen)}
        contentLabel="Vedors Details"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <Profile profileData={viewVendor}/>
      </Modal>

    </div>
  );
};

export default Vendors;

