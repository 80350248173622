import { useEffect, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { getAllPromoterSales, updatePromoterSalesStatusByGroup } from "../../calls/auths";
import { LuArrowUpToLine, LuEye } from "react-icons/lu";

const PrList = () => {
  const [promoterSales, setPromoterSales] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPromoterSales, setSelectedPromoterSales] = useState({});
  const [sliceCount, setSliceCount] = useState({ from: 0, to: 15 });
  const [groupedSales, setGroupedSales] = useState([]);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [selectedSalesBreakdown, setSelectedSalesBreakdown] = useState([]);
  // const [selectedStatus, setSelectedStatus] = useState("");
  const [activeTab, setActiveTab] = useState("pending");
  // const [selectedGroup, setSelectedGroup] = useState(null);
  const [errors, setErrors] = useState("");

  const filteredgroupedSales = groupedSales.filter(
    (group) => group.status === activeTab
  );  

  const getToken = () => {
    return localStorage.getItem("tripstore_login"); // Retrieve the token from localStorage
  };

  const openEditModal = (group) => {
    setSelectedPromoterSales({
      couponName: group.couponName, // Set couponName from the group object
      monthYear: group.monthYear,   // Set monthYear from the group object
      status: group.status,         // Set current status
    });
    setIsModalOpen(true); // Open the modal
  };  

  const openViewModal = (breakdown) => {
    setSelectedSalesBreakdown(breakdown);
    setIsViewModalOpen(true);
  };

  const addSlice = () => {
    if (sliceCount.to < groupedSales.length) {
      setSliceCount((prev) => ({ from: prev.from + 15, to: prev.to + 15 }));
      document.querySelector("#table-container")?.scrollTo(0, 0);
    }
  };

  const cutSlice = () => {
    if (sliceCount.from > 0) {
      setSliceCount((prev) => ({ from: prev.from - 15, to: prev.from }));
      document.querySelector("#table-container")?.scrollTo(0, 0);
    }
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedPromoterSales((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const groupSalesByMonthAndCoupon = (sales) => {
    const grouped = sales.reduce((acc, sale) => {
      const date = new Date(sale.createdAt);
      const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`; // Format: MM-YYYY
      const key = `${sale.couponName}-${monthYear}`;
      const status = sale.status;
      const orderNumber = sale.orderNumber;

      if (!acc[key]) {
        acc[key] = {
          fullname: `${sale.promoter.firstname} ${sale.promoter.lastname}`,
          couponName: sale.couponName,
          monthYear,
          status,
          orderNumber,
          totalAmount: 0,
          breakdown: [],
        };
      }

      acc[key].totalAmount += sale.total;
      acc[key].breakdown.push(sale);

      return acc;
    }, {});

    return Object.values(grouped);
  };

  useEffect(() => {
    const fetchPromoterSales = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.error("No token found");
          return;
        }

        const response = await getAllPromoterSales(token);
        // console.log("API Result:", response);

        if (response.status === 200) {
          const sales = response.data.map((sale) => ({
            ...sale,
            promoter: {
              firstname: sale.promoter.firstname,
              lastname: sale.promoter.lastname,
            },
          }));
          setPromoterSales(sales);
          setGroupedSales(groupSalesByMonthAndCoupon(sales));
        } else {
          setErrors(response.data || "Error fetching promoter sales.");
        }
      } catch (err) {
        setErrors("Failed to fetch promoter sales.");
      }
    };
    fetchPromoterSales();
  }, []);

  const handleUpdateStatus = async (e) => {
    e.preventDefault();
    const { couponName, monthYear, status } = selectedPromoterSales;
    console.log("API Call Data:", { couponName, monthYear, status });
  
    if (!couponName || !monthYear || !status) {
      alert("Please select all fields.");
      return;
    }

    try {
      const token = getToken();
      const response = await updatePromoterSalesStatusByGroup(
        couponName,
        monthYear,
        status,
        token
      );
  
      if (response.success) {
        // Update the local state for immediate UI update
        filteredgroupedSales((prevSales) =>
          prevSales.map((sale) =>
            sale.couponName === couponName && sale.monthYear === monthYear
              ? { ...sale, status } // Update the status
              : sale
          )
        );
        setIsModalOpen(false); // Close the modal
      } else {
        alert(response.message || "Failed to update status.");
      }
    } catch (error) {
      console.error("Error updating promoter sales status:", error);
      alert("An error occurred while updating the status.");
    }
  };

  return (
    <div className="mt-2 p-2">
      <div className="flex border-b">
        {["Pending", "Paid", "Failed", "Withhold"].map((tab) => (
          <button
            key={tab}
            className={`px-4 py-2 text-sm ${
              activeTab === tab
                ? "border-b-2 border-black text-black font-semibold"
                : "text-gray-500"
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="rounded-lg border border-gray-200 shadow-md mt-10">
        <div className="overflow-x-auto rounded-t-lg">
          <table
            className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm"
            id="table-container"
          >
            <thead>
              <tr>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Full Name
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Coupon Name
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Month-Year
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Total Amount
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Payment Status
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {filteredgroupedSales.length > 0 ? (
                filteredgroupedSales.map((group) => (
                  <tr key={`${group.couponName}-${group.monthYear}`}>
                    <td className="py-2 px-4 text-center">{group.fullname}</td>
                    <td className="py-2 px-4 text-center">
                      {group.couponName}
                    </td>
                    <td className="py-2 px-4 text-center">{group.monthYear}</td>
                    <td className="py-2 px-4 text-center">
                      ₦{formatPrice(group.totalAmount)}
                    </td>
                    <td className="py-2 px-4 text-center">{group.status}</td>
                    <td className="py-2 px-4 text-center">
                      {group.status === "Pending" ||
                      group.status === "Failed" ||
                      group.status === "Withhold" && (
                        <button
                          className="test-primary hover:underline"
                          onClick={() => openEditModal(group)}
                        >
                          <LuArrowUpToLine />
                        </button>
                      )}
                      <button
                        className="text-primary hover:underline"
                        onClick={() => openViewModal(group.breakdown)}
                      >
                        <LuEye />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="px-4 py-2 text-center" colSpan={4}>
                    No sales found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-96">
            <h2 className="text-lg font-bold mb-4">Update Promoter Sales</h2>
            <form onSubmit={handleUpdateStatus}>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  PaymentStatus
                </label>
                <select
                  name="status"
                  value={selectedPromoterSales.status}
                  onChange={handleInputChange}
                  className="w-full border border-gray-300 p-2 rounded-lg"
                >
                  <option value="Pending">Pending</option>
                  <option value="Paid">Paid</option>
                  <option value="Failed">Failed</option>
                  <option value="Withhold">Withhold</option>
                </select>
              </div>
              <div className="flex justify-end mt-4">
                <button
                  type="submit"
                  className="bg-black text-white px-4 py-2 rounded-lg"
                  disabled={!selectedPromoterSales.status}
                  > Update
                </button>
                <button
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                  className="ml-2 bg-yellow-500 text-white px-4 py-2 rounded-lg"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isViewModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-96">
            <h2 className="text-lg font-bold mb-4">Sales Breakdown</h2>
            <ul>
              {selectedSalesBreakdown.map((sale) => (
                <li key={sale._id} className="mb-2">
                  <p>Date: {new Date(sale.createdAt).toLocaleDateString()}</p>
                  <p>Order Number: {sale.orderNumber}</p>
                  <p>Amount: ₦{formatPrice(sale.total)}</p>
                </li>
              ))}
            </ul>
            <button
              className="mt-4 bg-black text-white px-4 py-2 rounded-lg"
              onClick={() => setIsViewModalOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div className="h-[10vh] w-[95%] m-auto flex justify-end items-center">
        <button
          onClick={() => cutSlice()}
          className="flex items-center gap-4 hover:bg-slate-200"
        >
          <FaAngleLeft /> <span className="hidden md:inline">Previous</span>
        </button>
        <div
          className={`${
            filteredgroupedSales.length <= 0 && "hidden"
          } text-sm font-semibold text-gray-500`}
        >
          Showing {1 + sliceCount.from} to{" "}
          {sliceCount.to >= filteredgroupedSales.length
            ? filteredgroupedSales.length
            : sliceCount.to}{" "}
          of {filteredgroupedSales.length}
        </div>
        <button
          onClick={() => addSlice()}
          className="flex items-center gap-4 hover:bg-slate-200"
        >
          <span className="hidden md:inline">Next</span> <FaAngleRight />
        </button>
      </div>
    </div>
  );
};

export default PrList;
