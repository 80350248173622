import React, { useEffect, useState } from 'react'
import { getVendorDashboardSummary } from '../../calls/auths';

const VendorDashboard = () => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [summary, setSummary] = useState(null);

  function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random()   
   * 16)];
    }
    return color;
  }
  
  const dashboardItems = document.querySelectorAll('.dashboard-item');
  dashboardItems.forEach(item => {
    item.style.backgroundColor = getRandomColor();
  });

  const getToken = () => {
    return localStorage.getItem('tripstore_login'); // Retrieve the token from localStorage
  };


  useEffect(() => {
    const fetchSummary = async () => {
      setIsLoading(true);
      setError(null);
      const token = getToken();

      try {
        const response = await getVendorDashboardSummary(token);
        setSummary(response.data);
        console.log('API Result:', response);
      } catch (error) {
        setError('An error occurred while fetching Vendor summary');
      } finally {
        setIsLoading(false);
      }
    };

    fetchSummary();
  }, []);

  if (isLoading) {
    return (
      <div>Loading....</div>
    )
  }


  return (
		<div className="bg-white min-h-screen p-6">
			<h1 className="text-2xl text-center font-semibold mb-3 uppercase">
				Stock
			</h1>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6 mb-8 items-center">
				<div className="bg-green-700 p-4 rounded hover:bg-orange-300 hover:animate-bounce">
					<h2 className="text-white font-bold">Available Stock</h2>
					<p className="text-4xl font-bold text-white py-2">
						{/* {totalQuantity.toLocaleString()} */}
            {summary ? summary.uploadedLast30Days.count.toLocaleString() : NaN}
            {/* 202 */}
					</p>
				</div>
				<div className="bg-yellow-500 p-4 rounded hover:bg-orange-300 hover:animate-bounce">
					<h2 className="text-gray-800 font-bold">Total No of Sold Stock</h2>
					<p className="text-4xl font-bold text-white py-2">
						{/* {totalSalesC.toLocaleString()} */}
            {summary ? summary.soldLast30Days.count.toLocaleString() : NaN}
            {/* 40 */}
					</p>
				</div>
				<div className="bg-blue-800 p-4 rounded hover:bg-orange-300 hover:animate-bounce">
					<h2 className="text-white font-bold">Total No of Stock</h2>
					<p className="text-4xl font-bold text-white py-2">
						{/* {totalStock.toLocaleString()} */}
            {summary ? summary.totalProductsEverUploaded.toLocaleString() : NaN}
            {/* 20 */}
					</p>
				</div>
				<div className="bg-gray-600 p-4 rounded hover:bg-orange-300 hover:animate-bounce">
					<h2 className="text-gray-800 font-bold">Total Cost Price</h2>
					<p className="text-4xl font-bold text-white py-2">
						{/* #{totalCostPrice.toLocaleString()} */}
            {summary ? summary.uploadedLast30Days.totalCostPrice.toLocaleString() : NaN}
            {/* 35  */}
					</p>
				</div>
				<div className="bg-orange-600 p-4 rounded hover:bg-orange-300 hover:animate-bounce">
					<h2 className="text-gray-800 font-bold">Total Value of Stock</h2>
					<p className="text-4xl font-bold text-white py-2">
						{/* #{totalPrice.toLocaleString()} */}
            #{
              summary ? (summary.uploadedLast30Days.totalCostPrice - summary.soldLast30Days.totalCostPrice).toLocaleString() : NaN
            }
            {/* # 340534 */}
					</p>
				</div>
			</div>
			
      {/* <h1 className="text-2xl text-center font-semibold mb-3 uppercase">
				Store Sales
			</h1>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6 mb-8">
				<div className="dashboard-item bg-gray-100 p-4 rounded shadow-md hover:scale-105 hover:shadow-lg">
					<h2 className="text-gray-800 font-bold">Total Sales</h2>
					<p className="text-4xl font-semibold text-white py-2">
						{salesCount ? salesCount.totalSales.toLocaleString() : "N/A"}
					</p>
				</div>
				<div className="dashboard-item bg-gray-100 p-4 rounded shadow-md hover:scale-105 hover:shadow-lg">
					<h2 className="text-gray-800 font-bold">Sales Last 30 Days</h2>
					<p className="text-4xl font-semibold text-white py-2">
						+{salesCount ? salesCount.salesLast30Days.toLocaleString() : "N/A"}
					</p>
					<p
						className={`${
							parseFloat(salesCount?.percentageChange) >= 0
								? "text-green-500"
								: "text-red-500"
						}`}
					>
						{salesCount
							? `${salesCount.percentageChange}% from previous 30 days`
							: "N/A"}
					</p>
				</div>
				{[
					"daily",
					"weekly",
					"monthly",
					"quarterly",
					"halfYearly",
					"yearly",
					"totalAllTime",
				].map((period) => (
					<div
						key={period}
						className="dashboard-item bg-gray-100 p-4 rounded shadow-md hover:scale-105 hover:shadow-lg"
					>
						<h2 className="text-gray-800 font-bold capitalize">
							{period.replace("Y", " Y")} Revenue
						</h2>
						<p className="text-4xl font-semibold text-white py-2">
							₦{" "}
							{salesSummary && salesSummary[period]
								? salesSummary[period].toLocaleString()
								: "0"}
						</p>
					</div>
				))}
			</div> */}
			

			
		</div>
	);
}

export default VendorDashboard