import { Route, Routes } from "react-router-dom"
import Products from "./Products"
import ProductDetail from "./ProductDetail"
// import EditProduct from "./EditProduct"
import ProductBarcode from "./ProductBarcode"

const Inventory = () => {

    return (
        <Routes>
            <Route element={<Products />} path="/" />
            <Route element={<ProductBarcode />} path="/barcode" />
            <Route element={<ProductDetail />} path="/detail/:productId" />
            {/* <Route element={<EditProduct />} path="/edit/:productId" />  */}
        </Routes>
    )
}

export default Inventory