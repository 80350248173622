import React, { useState, useRef, useEffect, useContext } from 'react';
import fetchProductInfo from './fetchProductInfo';
import PrintableInvoice from './PrintableInvoice';
import PrintableReceipt from './PrintableReceipt';
import Modal from './Modal';
import { useReactToPrint } from 'react-to-print';
import { useNavigate } from 'react-router-dom';
import { activateCoupon, API_ENDPOINT, checkPointDet, createSale, stockUpdate, getAllCoupons, savePromoterSales, getPromoterByCode, activatePromoterCode } from '../../calls/auths';
import { AppContext } from "../../context/AppContext";

const Sales = ({ pausedSale }) => {
  const { user } = useContext(AppContext);
  const [products, setProducts] = useState(pausedSale ? pausedSale.products : []);
  const [scannedBarcode, setScannedBarcode] = useState('');
  const [currentProduct, setCurrentProduct] = useState(null);
  const [order, setOrder] = useState(null);
  const [salesRep, setSalesRep] = useState(pausedSale ? pausedSale.salesRep : null);
  const [isCreditCardModalOpen, setIsCreditCardModalOpen] = useState(false);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [colorModalOpen, setColorModalOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const [transactionDetails, setTransactionDetails] = useState({});
  const [coupon, setCoupon] = useState('');
  const [promoterCode, setPromoterCode] = useState('');
  const [promoterCoupon, setPromoterCoupon] = useState('');
  // const [subtotal, setSubtotal] = useState(0);
  const [userPhone, setUserPhone] = useState('');
  const [pointsDet, setPointsDet] = useState(null);
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const inputRef = useRef();
  const invoiceRef = useRef();
  const receiptRef = useRef();
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [isFetching, setIsFetching] = useState(false); // To manage input disabling

  useEffect(() => {
    if (inputRef.current && !isFetching) {
      inputRef.current.focus();
    }
    if (!salesRep && user) {
      setSalesRep({
        id: user._id,
        name: `${user.firstname} ${user.lastname}`,
        email: user.email
      });
    }
  }, [salesRep, user, isFetching]);

   // Calculate total, VAT, and discounted total
   const calculateTotal = () => {
    return products.reduce((total, product) => total + product.price * product.quantity, 0);
  };

  const calculateVAT = (total) => {
    return total * 0.075;
  };

  const calculateDiscountedTotal = () => {
    const total = calculateTotal();
    const vat = calculateVAT(total);
    const grandTotal = total + vat;
    if (appliedCoupon) {
      const discountAmount = grandTotal * (appliedCoupon.discount / 100);
      return grandTotal - discountAmount;
    }

    if (pointsDet) {
      return pointsDet.discountedAmount
    }
    return grandTotal;
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price);
  };

  // Handle input change
  const handleInputChange = (e) => {
    setScannedBarcode(e.target.value);
  };

  // Handle quantity change in the table
  const handleQuantityChange = (e, index) => {
    const newQuantity = parseInt(e.target.value, 10);
    if (newQuantity < 1) return;
    const product = products[index];
    const availableStock = product.selectedColor.amount;

    if (newQuantity > availableStock) {
      alert(`Cannot increase the Product quantity more than (${availableStock}) available in stock  for this color.`);
      return;
    }

    setProducts(products.map((p, i) =>
      i === index ? { ...p, quantity: newQuantity } : p
    ));
  };

  // Handle reducing quantity by 1
  const handleReduceQuantity = (index) => {
    setProducts(products.map((p, i) =>
      i === index ? { ...p, quantity: p.quantity - 1 } : p
    ).filter((p) => p.quantity > 0));
  };

  // Handle removing a product from the table
  const handleRemoveProduct = (targetIndex) => {
    const newArray = products.filter((item, index) => index !== targetIndex);
    setProducts(newArray);
  };

  // Generate a unique order number
  const generateOrderNumber = () => {
    return `ORD-${Math.floor(Math.random() * 100000)}`;
  };

  const handlePrintInvoice = useReactToPrint({
    content: () => invoiceRef.current,  // Reference to PrintableInvoice
    documentTitle: "Customer Invoice",
  });

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
    onAfterPrint: () => {
      navigate('/admin/pos');
      window.location.reload();
    },
  });

  const handleInvoice = () => {
    if (products.length === 0) {
      alert('No products to preview.');
      return;
    }
  
    // Calculate order details for preview
    const subtotal = calculateTotal();
    const tax = calculateVAT(subtotal);
    const discountPercentage = appliedCoupon ? appliedCoupon.discount : 0;
    const discount = (subtotal + tax) * (discountPercentage / 100);
    const total = calculateDiscountedTotal();
  
    const previewOrderDetails = {
      orderNumber: generateOrderNumber(), // Temporary order number for preview
      date: new Date(),
      salesRep: salesRep,
      items: products.map(p => ({
        ...p,
        color: p.selectedColor.color,
        sku: p.selectedColor._id,
        size: p.size,
        quantity: p.quantity,
      })),
      subtotal,
      tax,
      discountAmount: discount,
      discountPercentage,
      points: pointsDet?.discountedAmount,
      couponName: coupon,
      total,
    };
  
    // Set the order state for the invoice preview
    setOrder(previewOrderDetails);
  
    // Slight delay to ensure the order state is set before printing
    setTimeout(() => {
      handlePrintInvoice(); // Call this to trigger the invoice print
    }, 100);
  };
  
const handleCheckout = async () => {
  if (products.length === 0) {
    alert('No products to checkout.');
    return;
  }

  const subtotal = calculateTotal();
  const tax = calculateVAT(subtotal);
  const discountPercentage = appliedCoupon ? appliedCoupon.discount : 0;  // Get the discount percentage
  const discount = (subtotal + tax) * (discountPercentage / 100);  // Calculate the discount amount
  const total = calculateDiscountedTotal();

  const paymentType = isTransferModalOpen ? 'Transfer' : 'Credit Card';

  const orderDetails = {
    orderNumber: generateOrderNumber(),
    date: new Date(),
    salesRep: salesRep,
    items: products.map(p => ({
      ...p,
      color: p.selectedColor.color,  
      sku: p.selectedColor._id,     
      size: p.size,                  
      updatedStock: p.selectedColor.amount - p.quantity,
    })),
    subtotal,
    tax,
    discountAmount: discount, 
    discountPercentage,
    points: pointsDet?.discountedAmount,
    couponName: coupon,
    total,
    payment: transactionDetails,
  };
  
  console.log("Order Details to Backend:", orderDetails);

  const token = localStorage.getItem('tripstore_login');

  try {
    // Create the sale order
    const response = await createSale(orderDetails, paymentType, token);
    console.log("Create sale response:", response.data);

    // Check if coupon belongs to a promoter
    if (coupon) {
      const promoterResponse = await getPromoterByCode(coupon);

      if (promoterResponse.status === 200 && promoterResponse.data) {
        const promoterDetails = promoterResponse.data;

        // Save promoter sales
        const promoterSaleDetails = {
          ...orderDetails,
          promoter: {
            firstname: promoterDetails.firstname,
            lastname: promoterDetails.lastname,
            email: promoterDetails.email,
            minimum_purchase: promoterDetails.minimum_purchase,
            pr_points: promoterDetails.pr_points
          },
        };
        await savePromoterSales(promoterSaleDetails, paymentType, token);
        console.log("Promoter sale saved successfully!:", promoterSaleDetails);
      } else if (promoterResponse.status === 404) {
        console.log("Coupon not found or invalid:", coupon);
      } else {
        console.error("Error checking promoter coupon:", promoterResponse);
      }
    } else {
      console.log('Coupon does not belong to any promoter:', appliedCoupon.name);
    }
    
    setOrder(orderDetails);

    setTimeout(() => {
      if (orderDetails) {
      handlePrint();
      } else {
        alert('There was an error processing the sale. Please try again.');
        console.error('Order details not found.');
      }
    }, 0);

    const tribe = await Promise.all(products.map(async (product) => {
      const { measurements, selectedColor, quantity, productid, measurementId } = product;
      console.log("frames: ", product);
    
      const stockUpdatePayload = {
        updateData: [
          {
            measurementId,
            colorId: selectedColor._id,
            amount: selectedColor.amount - quantity,
          },
        ],
      };
    
      try {
        const stockResponse = await stockUpdate(token, productid, stockUpdatePayload);
    
        if (stockResponse.status === 200) {
          console.log(`Stock updated successfully for product ${productid}`);
        } else {
          console.error(`Failed to update stock for product ${productid}:`, stockResponse.data);
        }
      } catch (error) {
        console.error(`Error updating stock for product ${productid}:`, error);
      }
    }));

    // Clear cart and products
    setCart([]);
    setProducts([]);

  } catch (error) {
    console.error('Error during checkout:', error.response?.data || error.message);
    alert('There was an error processing the sale. Please try again.');
  }
};

const subtotal = products.reduce((total, product) => {
  return total + product.price * product.quantity;
}, 0);


  const handlePaymentClick = (type) => {
    if (type === 'Credit Card') {
      setIsCreditCardModalOpen(true);
    } else if (type === 'Transfer') {
      setIsTransferModalOpen(true);
    } else {
      handleCheckout();
    }
  };

  // Save credit card details and proceed to checkout
  const handleCreditCardSave = () => {
    setIsCreditCardModalOpen(false);
    handleCheckout();
    handlePrint();
  };

  // Save transfer details and proceed to checkout
  const handleTransferSave = () => {
    setIsTransferModalOpen(false);
    handleCheckout();
    handlePrint();
  };

  const handleCancelSale = () => {
    setProducts([]);
    setScannedBarcode('');
    navigate('/admin/pos');
    window.location.reload();
  };

  const handlePauseSale = () => {
    const pausedSale = { products, salesRep, orderNumber: generateOrderNumber() };
    localStorage.setItem(`pausedSale-${pausedSale.orderNumber}`, JSON.stringify(pausedSale));
    window.open('/admin/paused-sales', '_blank'); // Open paused sales in a new tab
    navigate('/admin/pos'); // Navigate to a fresh sales page
    window.location.reload(); // Reload the current page to reset the state
  };

  const handlePausedSale = () => {
    window.open('/admin/paused-sales'); // Open paused sales in a new tab
    navigate('/admin/pos'); // Navigate to a fresh sales page
    window.location.reload(); // Reload the current page to reset the state
  };

  const handleTransactionDetailsChange = (e) => {
    setTransactionDetails({
      ...transactionDetails,
      [e.target.name]: e.target.value,
    });
  };

async function getCouponInfo(coupon, promoterCode, subtotal) {
  try {
    // Handle the regular coupon if no promoterCode is provided
    if (coupon) {
      const couponResponse = await activateCoupon(coupon);

      if (couponResponse.data && couponResponse.status === 200) {
        setAppliedCoupon(couponResponse.data); // Apply the regular coupon
        alert('Coupon applied successfully!');
        console.log('coupon inputed is:', coupon);
      } else if (promoterCode) {
        const promoterResponse = await activatePromoterCode(promoterCode);
  
        if (promoterResponse.status === 200) {
          const promoterData = promoterResponse.data;
          setAppliedCoupon(promoterResponse.data);
  
          // Check if subtotal meets the minimum_purchase requirement
          if (subtotal < promoterData.minimum_purchase) {
            alert(
              `You can't use this coupon until you buy a product worth ₦${promoterData.minimum_purchase}`
            );
            return; // Stop execution if minimum_purchase is not met
          }

          console.log('coupon inputed is:', coupon);
  
          setPromoterCoupon(promoterData); // Apply the promoter coupon
          alert('Promoter coupon applied successfully!');
        } else {
          console.error('Promoter Code Error:', promoterResponse.data);
          alert(
            promoterResponse.data.message ||
              'Invalid or expired promoter code. Please try again.'
          );
        }
      }
      else {
        alert('Invalid or expired coupon. Please try again.');
      }
    }
  } catch (error) {
    // Handle errors for both coupon and promoter code
    console.error('Error activating coupon or promoter code:', error);

    if (error.response) {
      alert(error.response.data.message || 'Invalid or expired coupon. Please try again.');
    } else {
      alert('There was an error applying the coupon. Please try again later.');
    }
  }
}

  async function getPointDets(phone, amount) {
    try {
      const response = await checkPointDet(phone, amount);
      if (response.data) {
        setPointsDet(response.data.data);
        alert('Point Processed successfully!');
      } else {
        alert('Unable to process Points.');
      }
    } catch (error) {
      console.error('Error activating points:', error);
      alert('There was an error applying the points.');
    }
  }

  const handleUserPhoneChange = (event) => {
    setUserPhone(event.target.value);
  }

  const handleCouponInputChange = (event) => {
    setCoupon(event.target.value);
    setPromoterCode(event.target.value);
  };

  const isBarcode = (input) => {
    return input.length === 13 && input.startsWith('ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789');
  };

  const handleButtonClick = () => {
    if (appliedCoupon || !coupon) {
      return; // Do nothing if appliedCoupon is already true or coupon is empty
    }
    getCouponInfo(coupon, promoterCode, subtotal);
  };

  const handlePointClick = () => {
    getPointDets(userPhone, (calculateTotal() + calculateVAT(calculateTotal())));
  };

  // Handle scanning a product barcode
  const handleScan = async (barcode) => {
    setIsFetching(true); // Disable input
    const baseUrl = API_ENDPOINT();
    try {
      const scannedProduct = await fetchProductInfo(barcode, baseUrl);

      if (!scannedProduct) {
        alert('Product not found.');
        setIsFetching(false);
        return;
      }

      if (scannedProduct.colors.length > 1) {
        setSelectedProduct(scannedProduct);
        setColorModalOpen(true);
      } else {
        if (scannedProduct.colors[0].amount === 0) {
          alert('OUT OF STOCK');
          setIsFetching(false);
          return;
        }
        // Single color product, add directly
        addProduct(scannedProduct, scannedProduct.colors[0]);
      }

      setScannedBarcode('');
    } catch (error) {
      console.error(error);
      alert('Error fetching product information.');
    } finally {
      setIsFetching(false); // Re-enable input
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  // Handle manual input of product number
  const handleManualInput = async (productNumber) => {
    setIsFetching(true); // Disable input
    const baseUrl = API_ENDPOINT();
    try {
      const product = await fetchProductInfo(productNumber, baseUrl);

      if (!product) {
        alert('Product not found.');
        setIsFetching(false);
        return;
      }

      if (product.colors.length > 1) {
        setSelectedProduct(product);
        setColorModalOpen(true);
      } else {
        if (product.colors[0].amount === 0) {
          alert('OUT OF STOCK');
          setIsFetching(false);
          return;
        }
        // Single color product, add directly
        addProduct(product, product.colors[0]);
      }

      setScannedBarcode('');
    } catch (error) {
      console.error(error);
      alert('cant fetching product information.');
    } finally {
      setIsFetching(false); // Re-enable input
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  // Add product with selected color
  const addProduct = (product, color) => {
    // Check if the product with the same color already exists
    const existingProductIndex = products.findIndex(
      (p) => p.barcode === product.barcode && p.selectedColor._id === color._id
    );

    if (existingProductIndex !== -1) {
      const existingProduct = products[existingProductIndex];
      if (existingProduct.quantity + 1 > color.amount || color.amount === 0) {
        alert('OUT OF STOCK');
        return;
      } else if (existingProduct.quantity + 1 > color.amount) {
        alert(`Cannot add more than ${color.amount} units of this product.`);
        return;
      }
      setProducts((prevProducts) =>
        prevProducts.map((p, index) =>
          index === existingProductIndex
            ? { ...p, quantity: p.quantity + 1 }
            : p
        )
      );
    } else {
      if (color.amount === 0) {
        alert('OUT OF STOCK');
        return;
      }
      if (color.amount < product.quantity + 1) {
        alert(`Cannot add more than ${color.amount} units of this product.`);
        return;
      }
      setProducts((prevProducts) => [
        ...prevProducts,
        { ...product, quantity: 1, selectedColor: color }
      ]);
    }
  };

  // Handle color selection from the modal
  const handleColorSelection = () => {
    if (!selectedColor) {
      alert('Please select a color.');
      return;
    }
    if (selectedColor.amount === 0) {
      alert('OUT OF STOCK');
      return;
    }
    if (selectedColor.amount < selectedProduct.quantity + 1) {
      alert(`Cannot add more than ${selectedColor.amount} units of this product.`);
      return;
    }
    addProduct(selectedProduct, selectedColor);
    setSelectedColor(null);
    setSelectedProduct(null);
    setColorModalOpen(false);
  };

  // Color Modal Component
  const ColorModalComponent = () => {
    if (!colorModalOpen) return null;

    return (
      <Modal
        isOpen={colorModalOpen}
        onClose={() => {
          setColorModalOpen(false);
          setSelectedProduct(null);
        }}
        onSave={handleColorSelection}
        saveButtonText="Select Color"
      >
        <h2 className="text-xl mb-4">Select Color</h2>
        <div className="flex flex-wrap">
          {selectedProduct.colors.map((color) => (
            <button
              key={color._id}
              className={`flex items-center m-2 p-2 border rounded ${
                selectedColor && selectedColor._id === color._id
                  ? 'border-black'
                  : 'border-gray-300'
              }`}
              onClick={() => setSelectedColor(color)}
            >
              <div
                style={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: color.color,
                  borderRadius: '50%',
                  display: 'inline-block',
                }}
                className="mr-2"
              />
              <span>{color.color}</span>
            </button>
          ))}
        </div>
      </Modal>
    );
  };

  return (
    <div className="flex flex-col md:flex-row">
      {/* Products Section */}
      <div className="w-full md:w-3/4 p-4">
        {/* Input and Action Buttons */}
        <div className="flex flex-col md:flex-row justify-between items-center w-full mb-4">
          <input
            ref={inputRef}
            type="text"
            value={scannedBarcode}
            onChange={handleInputChange}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                if (scannedBarcode) {
                  if (isBarcode(scannedBarcode)) {
                    handleScan(scannedBarcode);
                  } else {
                    handleManualInput(scannedBarcode);
                  }
                }
              }
            }}
            placeholder="Scan or enter product number..."
            className="p-2 border rounded w-full md:w-3/4"
            disabled={isFetching}
          />

          <button
            onClick={handlePauseSale}
            className="my-5 md:my-0 w-full md:w-52 md:ml-2 bg-yellow-500 text-white px-4 py-2 rounded"
          >
            Pause Sale
          </button>
          <button
            onClick={handlePausedSale}
            className="my-5 md:my-0 w-full md:w-52 md:ml-2 bg-green-500 text-white px-4 py-2 rounded"
          >
            Paused Sales
          </button>
          <button
            onClick={handleCancelSale}
            className="w-full md:w-52 md:ml-2 bg-red-500 text-white px-4 py-2 rounded"
          >
            Cancel Sale
          </button>
        </div>

        {/* Products Table */}
        <table className="w-full bg-green-100 rounded mb-4">
          <thead>
            <tr>
              <th className="p-2">Item</th>
              <th className="p-2">Size</th>
              <th className="p-2">Color</th>
              <th className="p-2">Price</th>
              <th className="p-2">Qty</th>
              <th className="p-2">Subtotal</th>
              <th className="p-2">Stock</th>
              <th className="p-2">Amount</th>
              <th className="p-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.length === 0 && (
              <tr>
                <td colSpan="8" className="p-4 text-center">
                  No products added.
                </td>
              </tr>
            )}
            {products.map((item, index) => (
              <tr key={`${item.barcode}-${item.selectedColor._id}`} className='text-center'>
                <td className="p-2">{item.name}</td>
                <td className="p-2">{item.size}</td>
                <td className="p-2 flex items-center justify-center">
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: item.selectedColor.color,
                      borderRadius: '50%',
                      display: 'inline-block',
                    }}
                    className="mr-2"
                  />
                  {/* <span>{item.selectedColor.color}</span> */}
                </td>
                <td className="p-2">₦{formatPrice(item.price)}</td>
                <td className="p-2">
                  <input
                    type="number"
                    value={item.quantity}
                    onChange={(e) => handleQuantityChange(e, index)}
                    min="1"
                    max={item.selectedColor.amount}
                    className="w-16 p-1 border rounded"
                  />
                </td>
                <td className="p-2">₦{formatPrice(item.price * item.quantity)}</td>
                <td className='p-2'>{item.selectedColor.amount}</td>
                <td className='p-2'>
                  {
                  // item.selectedColor.amount - item.quantity <= 0 ? (
                  //   alert('Out of Stock')
                  // ) : (
                    item.selectedColor.amount - item.quantity
                 // )
                  }
                </td>
                <td className="p-2">
                  <button
                    onClick={() => handleReduceQuantity(index)}
                    className="bg-yellow-500 text-white px-2 py-1 rounded"
                  >
                    Reduce
                  </button>
                  <button
                    onClick={() => handleRemoveProduct(index)}
                    className="bg-red-500 text-white px-2 py-1 rounded ml-2"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Summary and Actions Section */}
      <div className="w-full md:w-1/4 p-4">
        <div className="bg-white p-4 rounded shadow">
          <h1 className="text-xl font-bold mb-2">
            Total:
          {appliedCoupon || pointsDet ? (
            <>
              {appliedCoupon && (
                <>
                  <span className="line-through text-red-500">
                    ₦{formatPrice(calculateTotal() + calculateVAT(calculateTotal()))}
                  </span>{' '}
                  <span className="text-green-500">
                    ₦{formatPrice(calculateDiscountedTotal())}
                  </span>
                </>
              )}

              {pointsDet && (
                <>
                  <span className="line-through text-red-500">
                    ₦{formatPrice(calculateTotal() + calculateVAT(calculateTotal()))}
                  </span>{' '}
                  <span className="text-green-500">
                    ₦{formatPrice(calculateDiscountedTotal())}
                  </span>
                </>
              )}
            </>
          ) : (
            <span>₦{formatPrice(calculateTotal() + calculateVAT(calculateTotal()))}</span>
          )}
          </h1>
          <h1>Subtotal: ₦{formatPrice(calculateTotal())}</h1>
          <h1>VAT (7.5%): ₦{formatPrice(calculateVAT(calculateTotal()))}</h1>
          {appliedCoupon && (
            <h1>
              Discount ({appliedCoupon.discount}%): -₦{formatPrice((calculateTotal() + calculateVAT(calculateTotal())) * (appliedCoupon.discount / 100))}
            </h1>
          )}

          {/* Coupon Section */}
          <div className="mt-4">
            <input 
            required
              type="text"
              id="coupon"
              name='couponName'
              placeholder='Enter Coupon code' 
              value={coupon}
              onChange={handleCouponInputChange}
              className="w-full p-2 border rounded"
              disabled={appliedCoupon !== null}
            />
            <button 
              onClick={handleButtonClick}
              className={`w-full rounded mt-2 px-4 py-2 ${
                appliedCoupon ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary text-white'
              }`}
              disabled={appliedCoupon !== null}
            >
              Activate Coupon 🎉
            </button>
          </div>

          {/* Phone Number Section */}
          <div className="mt-4">
            <input 
              type="text"
              id="phone"
              name='Phone'
              placeholder='User Phone' 
              value={userPhone}
              onChange={handleUserPhoneChange}
              className="w-full p-2 border rounded"
              disabled={pointsDet !== null}
            />
            <button 
              onClick={handlePointClick}
              className={`w-full rounded mt-2 px-4 py-2 ${
                appliedCoupon ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary text-white'
              }`}
              disabled={pointsDet !== null}
            >
              Check User
            </button>
          </div>

          {/* Payment Buttons */}
          <div className="mt-6">
          <button
        onClick={handleInvoice}  // Print Invoice only
        className="bg-green-500 text-white w-full py-2 rounded mb-2"
      >
        Print Invoice
      </button>
            <button
              onClick={() => handlePaymentClick('Credit Card')}
              className="bg-green-500 text-white w-full py-2 rounded mb-2"
            >
              Credit Card
            </button>
            <button
              onClick={() => handlePaymentClick('Transfer')}
              className="bg-green-500 text-white w-full py-2 rounded mb-2"
            >
              Transfer
            </button>
          </div>
        </div>
      </div>

      {/* Receipt Component (Hidden) */}
      {order && (
        <div className='hidden'>
          <PrintableInvoice ref={invoiceRef} order={order} />
        </div>
      )}
      
      {order && (
        <div className="hidden">
          <PrintableReceipt ref={receiptRef} order={order} />
        </div>
      )}

      {/* Credit Card Payment Modal */}
      <Modal
        isOpen={isCreditCardModalOpen}
        onClose={() => setIsCreditCardModalOpen(false)}
        onSave={handleCreditCardSave}
        saveButtonText="Ok"
      >
        <h2 className="text-xl mb-4">Credit Card Payment</h2>
        <div className="mb-4">
          <label className="block mb-2">Transaction No:</label>
          <input
            type="text"
            name="transactionNo"
            onChange={handleTransactionDetailsChange}
            className="p-2 border rounded w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Card Type:</label>
          <input
            type="text"
            name="cardType"
            onChange={handleTransactionDetailsChange}
            className="p-2 border rounded w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">First 6 Digits:</label>
          <input
            type="text"
            name="first6Digits"
            onChange={handleTransactionDetailsChange}
            className="p-2 border rounded w-full"
            maxLength="6"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Last 4 Digits:</label>
          <input
            type="text"
            name="last4Digits"
            onChange={handleTransactionDetailsChange}
            className="p-2 border rounded w-full"
            maxLength="4"
          />
        </div>
      </Modal>

      {/* Transfer Payment Modal */}
      <Modal
        isOpen={isTransferModalOpen}
        onClose={() => setIsTransferModalOpen(false)}
        onSave={handleTransferSave}
        saveButtonText="Ok"
      >
        <h2 className="text-xl mb-4">Transfer Payment</h2>
        <div className="mb-4">
          <label className="block mb-2">Transaction ID:</label>
          <input
            type="text"
            name="transactionNo"
            onChange={handleTransactionDetailsChange}
            className="p-2 border rounded w-full"
          />
        </div>
      </Modal>

      {/* Color Selection Modal */}
      <ColorModalComponent />
    </div>
  );
};

export default Sales;
