import React, { useState, useContext, useEffect } from 'react';
import Popup from 'reactjs-popup';
import './adminstyle.css';
import { promoterRegister, getPromoter } from '../../calls/auths';
import { AppContext } from '../../context/AppContext';

const Promoter = () => {
    const { user } = useContext(AppContext);
    const [formData, setFormData] = useState({
        bankname: '',
        accountnumber: '',
        accountname: '',
        twitter: '',
        instagram: '',
        facebook: '',
        linkedin: '',
        tiktok: '',
        snapchat: '',
        firstname: user?.firstname || '',
        lastname: user?.lastname || '',
        email: user?.email || '',
    });

    const [isLoading, setIsLoading] = useState(false);
    const [promoter, setPromoter] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const token = window.localStorage.getItem("tripstore_login");

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            bankname: e.target.bankname.value,
            accountnumber: e.target.accountnumber.value,
            accountname: e.target.accountname.value,
            twitter: e.target.twitter.value,
            instagram: e.target.instagram.value,
            facebook: e.target.facebook.value,
            linkedin: e.target.linkedin.value,
            tiktok: e.target.tiktok.value,
            snapchat: e.target.snapchat.value,
            firstname: user?.firstname || '',
            lastname: user?.lastname || '',
            email: user?.email || '',
            status: 'pending',
        };

        setIsLoading(true);
        try {
            const response = await promoterRegister(formData, token);
            setIsLoading(false);

            if (response.data.msg.includes('Registered Successfully')) {
                alert('Registration Successful');
                window.location.reload();
            } else if (response.data.msg.includes('Already Registered')) {
                alert('You have already registered');
            } else {
                alert('Registration Failed. You have already registered');
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error registering promoter:', error);
            alert('An error occurred while registering promoter. Please try again.');
        }
    };

    const fetchPromoter = async () => {
        try {
            const response = await getPromoter(user?.email, token);
            console.log('Response Data:', response.data); // Debugging the response
    
            if (response.data) {
                const allPromoter = response.data;
                console.log('All Promoters:', allPromoter); // Debug the type of allPromoter
    
                setTotalItems(allPromoter.length);
    
                const startIndex = (currentPage - 1) * itemsPerPage;
                const paginatedPromoter = allPromoter.slice(startIndex, startIndex + itemsPerPage); // This line fails if allPromoter is not an array
    
                setPromoter(paginatedPromoter);
            } else {
                console.error('Failed to fetch promoter:', response.data);
            }
        } catch (error) {
            console.error('Error fetching promoter:', error);
        }
    };    

    useEffect(() => {
        fetchPromoter();
    }, [currentPage]);

  return (
    <div className='mt-2 p-2'>
        <Popup trigger={<button className="mb-3 inline-block rounded border border-black bg-black px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-black focus:outline-none focus:ring active:text-black">Join Promoter</button>} position="center" modal>
        <div className='w-full py-16 overflow-y-auto'>
            <div className="mx-auto w-full">
                <form onSubmit={handleSubmit} className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8 border border-black animated-border bg-white">
                    <p className="text-center text-lg font-medium">Promoter Registration</p>
                    <div className='flex flex-col md:flex-row justify-between items-center'>                    
                    <div className='flex flex-col'>
            <label htmlFor="bankname">Bank Name</label>
            <select name="bankname" id="bankname" className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm" onChange={(e) => {
              const bankName = e.target.value;
              const accountNumber = document.getElementById('accountnumber').value;
              const accountNameInput = document.getElementById('accountname');
              if (bankName && accountNumber) {
                fetch(`https://api.paystack.co/bank/resolve?account_number=${accountNumber}&bank_code=${bankName}&type=ngn&currency=NGN`, {
                  headers: {
                    Authorization: "Bearer sk_test_8f3baa6f4d7f0b6d4d6e5f7c2b3a1b2a3c4d5e6f7g8h9i0j1k2l3m4n5o6p7q8r9s0t1u2v3w4x5y6z",
                  },
                })
                  .then(res => res.json())
                  .then(data => {
                    if (data.status === true) {
                      accountNameInput.value = data.data.account_name;
                    } else {
                      accountNameInput.value = '';
                    }
                  })
                  .catch(err => console.error(err));
              }
            }}>
              <option value="">Select Bank</option>
              <option value="Access Bank">Access Bank</option>
              <option value="ALAT by Wema">ALAT by Wema</option>
              <option value="Asharami Synergy Frontiers">Asharami Synergy Frontiers</option>
              <option value="Banco Wallet">Banco Wallet</option>
              <option value="Bank of Industry">Bank of Industry</option>
              <option value="Carbon">Carbon</option>
              <option value="Celullant">Celullant</option>
              <option value="Citibank Nigeria">Citibank Nigeria</option>
              <option value="Coronation Merchant Bank">Coronation Merchant Bank</option>
              <option value="EcoBank">EcoBank</option>
              <option value="Eyowo">Eyowo</option>
              <option value="FBNQuest Merchant Bank">FBNQuest Merchant Bank</option>
              <option value="FCMB">FCMB</option>
              <option value="Fidelity Bank">Fidelity Bank</option>
              <option value="First Bank of Nigeria">First Bank of Nigeria</option>
              <option value="First City Monument Bank">First City Monument Bank</option>
              <option value="Globus Bank">Globus Bank</option>
              <option value="Guaranty Trust Bank">Guaranty Trust Bank</option>
              <option value="Heritage Bank">Heritage Bank</option>
              <option value="Keystone Bank">Keystone Bank</option>
              <option value="Kuda">Kuda</option>
              <option value="Mintyn Bank">Mintyn Bank</option>
              <option value="Moniepoint Microfinance Bank">Moniepoint Microfinance Bank</option>
              <option value="NPF Microfinance Bank">NPF Microfinance Bank</option>
              <option value="OneFi">OneFi</option>
              <option value="Paga">Paga</option>
              <option value="PalmPay">PalmPay</option>
              <option value="Paycom">Paycom</option>
              <option value="Paystack">Paystack</option>
              <option value="Polaris Bank">Polaris Bank</option>
              <option value="Rand Merchant Bank">Rand Merchant Bank</option>
              <option value="Rubies Bank">Rubies Bank</option>
              <option value="Spectra">Spectra</option>
              <option value="Stanbic IBTC Bank">Stanbic IBTC Bank</option>
              <option value="Sterling Bank">Sterling Bank</option>
              <option value="Tangerine">Tangerine</option>
              <option value="Titan Trust Bank">Titan Trust Bank</option>
              <option value="UBA">UBA</option>
              <option value="Union Bank">Union Bank</option>
              <option value="Unity Bank">Unity Bank</option>
              <option value="VFD Microfinance Bank">VFD Microfinance Bank</option>
              <option value="Wema Bank">Wema Bank</option>
              <option value="Zenith Bank">Zenith Bank</option>
            </select>
          </div>
          <div className='flex flex-col mx-4'>
                        <label htmlFor="accountnumber">Account Number</label>
                        <div className="relative">
                            <input
                                type="text"
                                id="accountnumber"
                                name="accountnumber"
                                placeholder="Account Number"
                                className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                value={formData.accountnumber}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className='flex flex-col'>
                        <label htmlFor="accountname">Account Name</label>
                        <div className="relative">
                            <input
                                type="text"
                                id="accountname"
                                name="accountname"
                                placeholder="Account Name"
                                className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                value={formData.accountname}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div> 
                    </div>
                    <div className='flex flex-col md:flex-row'>
                    <div className='flex flex-col'>
                        <label htmlFor="twitter">Twitter</label>
                        <div className="relative">
                            <input
                                type="text"
                                id="twitter"
                                name="twitter"
                                placeholder="Twitter"
                                className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                value={formData.twitter}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className='flex flex-col mx-4'>
                        <label htmlFor="instagram">Instagram</label>
                        <div className="relative">
                            <input
                                type="text"
                                id="instagram"
                                name="instagram"
                                placeholder="Instagram"
                                className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                value={formData.instagram}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className='flex flex-col'>
                        <label htmlFor="facebook">Facebook</label>
                        <div className="relative">
                            <input
                                type="text"
                                id="facebook"
                                name="facebook"
                                placeholder="Facebook"
                                className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                value={formData.facebook}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    </div>
                    <div className='flex flex-col md:flex-row'>
                    <div className='flex flex-col'>
                        <label htmlFor="linkedin">Linkedin</label>
                        <div className="relative">
                            <input
                                type="text"
                                id="linkedin"
                                name="linkedin"
                                placeholder="Linkedin"
                                className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                value={formData.linkedin}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className='flex flex-col mx-4'>
                        <label htmlFor="tiktok">Tiktok</label>
                        <div className="relative">
                            <input
                                type="text"
                                id="tiktok"
                                name="tiktok"
                                placeholder="Tiktok"
                                className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                value={formData.tiktok}
                                onChange={handleInputChange}                                
                            />
                        </div>
                    </div>

                    <div className='flex flex-col'>
                        <label htmlFor="snapchat">Snapchat</label>
                        <div className="relative">
                            <input
                                type="text"
                                id="snapchat"
                                name="snapchat"
                                placeholder="Snapchat"                                
                                className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                value={formData.snapchat}
                                onChange={handleInputChange}                                
                            />
                        </div>
                    </div>
                    </div>
                <div className="flex justify-end mt-4">
                    <button type="submit" className="bg-black text-white px-4 py-2 rounded">Save</button>
                </div>
            </form>
            </div>
        </div>
        </Popup>
        <div className='rounded-lg border border-gray-200 shadow-md mt-10'>
            <div className='overflow-x-auto rounded-t-lg'>
                <table className='min-w-full divide-y-2 divide-gray-200 bg-white text-sm'>
                    <thead>
                        <tr>
                            <th className='whitespace-nowrap px-4 py-2 font-medium text-gray-900'>
                                Full Name
                            </th>
                            <th className='whitespace-nowrap px-4 py-2 font-medium text-gray-900'>
                                Promoter Code
                            </th>
                            <th className='whitespace-nowrap px-4 py-2 font-medium text-gray-900'>
                                Date of Purchase
                            </th>
                            <th className='whitespace-nowrap px-4 py-2 font-medium text-gray-900'>
                                Amount (%)
                            </th>
                            <th className='whitespace-nowrap px-4 py-2 font-medium text-gray-900'>
                                Amount (NGN)
                            </th>
                            <th className='whitespace-nowrap px-4 py-2 font-medium text-gray-900'>
                                PR Points
                            </th>
                            <th className='whitespace-nowrap px-4 py-2 font-medium text-gray-900'>
                                Points(NGN)
                            </th>
                            <th className='whitespace-nowrap px-4 py-2 font-medium text-gray-900'>
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200'>
                        {user && user.promoters && user.promoters.map((promoter) => (
                            <tr key={promoter._id}>
                                <td className='whitespace-nowrap px-4 py-2 font-medium text-gray-900'>
                                    {promoter.firstname} {promoter.lastname}
                                </td>
                                <td className='whitespace-nowrap px-4 py-2 text-gray-700'>{promoter.code}</td>
                                <td className='whitespace-nowrap px-4 py-2 text-gray-700'>{promoter.date}</td>
                                <td className='whitespace-nowrap px-4 py-2 text-gray-700'>{promoter.discount}</td>
                                <td className='whitespace-nowrap px-4 py-2 text-gray-700'>{promoter.total}</td>
                                <td className='whitespace-nowrap px-4 py-2 text-gray-700'>{promoter.pr_points}</td>
                                <td className='whitespace-nowrap px-4 py-2 text-gray-700'>{(promoter.pr_points/100)*promoter.total}</td>
                                <td className='whitespace-nowrap px-4 py-2 text-gray-700'>{promoter.status}</td>
                            </tr>
                        ))}

                        {user && user.promoters && user.promoters.length > 0 && user.promoters.every(promoter => promoter.status === 'Pending') && (
                            <tr>
                                <td colSpan={7} className='whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-right'>
                                    Total:
                                </td>
                                <td className='whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-right'>
                                    {user.promoters.reduce((total, promoter) => total + promoter.total, 0)}
                                </td>
                                <td className='whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-right'>
                                    {(user.promoters.reduce((total, promoter) => total + (promoter.pr_points/100)*promoter.total, 0)).toFixed(2)}
                                </td>
                            </tr>
                        )}

                        {user && (!user.promoters || user.promoters.length === 0) && (
                            <tr>
                                <td colSpan={8} className='whitespace-nowrap px-4 py-2 font-medium text-gray-900 text-center'>
                                    No promoters found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  )
}

export default Promoter
