import React, { useState, useEffect } from 'react';
import { getAllPromoters, updatePromoter } from '../../calls/auths';
import { LuArrowUpToLine, LuEye } from 'react-icons/lu';

const Promoters = () => {
    const [promoters, setPromoters] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPromoter, setSelectedPromoter] = useState({});
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('Pending');

    const filteredPromoters = promoters.filter((promoter) => promoter.status === activeTab);

    const openEditModal = (promoter) => {
        setSelectedPromoter(promoter);
        setIsModalOpen(true);
    };

    const openViewModal = (promoter) => {
        setSelectedPromoter(promoter);
        setIsViewModalOpen(true);
    };

    const formatPrice = (price) => {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price);
      };

    const token = window.localStorage.getItem("tripstore_login");

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedPromoter((prev) => ({ ...prev, [name]: value }));
    };

    useEffect(() => {
        const fetchPromoters = async () => {
            try {
                const response = await getAllPromoters(token);
                if (response.status === 200) {
                    setPromoters(response.data);
                } else {
                    setError(response.data);
                }
            } catch (err) {
                setError('Failed to fetch promoters');
            }
        };
        fetchPromoters();
    }, [token]);

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await updatePromoter(selectedPromoter._id, selectedPromoter, token);
            if (response.status === 200) {
                setPromoters((prevPromoters) =>
                    prevPromoters.map((p) =>
                        p._id === selectedPromoter._id ? response.data : p
                    )
                );
                setIsModalOpen(false);
            } else {
                setError(response.data);
            }
        } catch (error) {
            setError('Failed to update promoter');
        }
    };

  return (
    <div className='mt-2 p-2'>
        <div className='flex border-b'>
                {['pending', 'active', 'inactive', 'suspended'].map((tab) => (
                    <button
                        key={tab}
                        className={`px-4 py-2 text-sm ${
                            activeTab === tab
                                ? 'border-b-2 border-black text-black font-semibold'
                                : 'text-gray-500'
                        }`}
                        onClick={() => setActiveTab(tab)}
                    >
                        {tab}
                    </button>
                ))}
            </div>
      <div className='rounded-lg border border-gray-200 shadow-md mt-10'>
            <div className='overflow-x-auto rounded-t-lg'>
                <table className='min-w-full divide-y-2 divide-gray-200 bg-white text-sm'>
                    <thead>
                        <tr>
                            <th className='whitespace-nowrap px-4 py-2 font-medium text-gray-900'>
                                Full Name
                            </th>
                            <th className='whitespace-nowrap px-4 py-2 font-medium text-gray-900'>
                                Bank Name
                            </th>
                            <th className='whitespace-nowrap px-4 py-2 font-medium text-gray-900'>
                                Account Number
                            </th>
                            <th className='whitespace-nowrap px-4 py-2 font-medium text-gray-900'>
                               Account Name
                            </th>
                            <th className='whitespace-nowrap px-4 py-2 font-medium text-gray-900'>
                                Status
                            </th>
                            <th className='whitespace-nowrap px-4 py-2 font-medium text-gray-900'>
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200'>
                        {filteredPromoters.length > 0 ? (
                            filteredPromoters.map((promoter) => (
                                <tr key={promoter._id}>
                                    <td className='px-4 py-2'>{`${promoter.firstname} ${promoter.lastname}`}</td>
                                    <td className='px-4 py-2'>{promoter.bankname}</td>
                                    <td className='px-4 py-2'>{promoter.accountnumber}</td>
                                    <td className='px-4 py-2'>{promoter.accountname}</td>
                                    <td className='px-4 py-2'>{promoter.status}</td>
                                    <td className='px-4 py-2'>
                                        <div className='flex'>                            
                                        <button
                                            className='text-blue-500 hover:underline'
                                            onClick={() => openEditModal(promoter)}
                                        >
                                         <LuArrowUpToLine/>
                                        </button>
                                        <button
                                            className='text-blue-500 hover:underline'
                                            onClick={() => openViewModal(promoter)}
                                        >
                                         <LuEye/>
                                        </button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className='px-4 py-2 text-center' colSpan={11}>No Promoters Found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
        {isModalOpen && (
                <div className='fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50'>
                    <div className='bg-white p-6 rounded-lg w-96'>
                        <h2 className='text-lg font-bold mb-4'>Update Promoter</h2>
                        <form onSubmit={handleUpdate}>
                            <div className='mb-4'>
                                <label className='block text-sm font-medium text-gray-700'>Code</label>
                                <input
                                    type='text'
                                    name='code'
                                    value={selectedPromoter.code || ''}
                                    onChange={handleInputChange}
                                    className='w-full border border-gray-300 p-2 rounded-lg'
                                />
                            </div>
                            <div>
                                <label className='block text-sm font-medium text-gray-700'>Start Date</label>
                                <input
                                    type='date'
                                    name='start_date'
                                    value={selectedPromoter.start_date || ''}
                                    onChange={handleInputChange}
                                    min={new Date().toISOString().split('T')[0]}
                                    className='w-full border border-gray-300 p-2 rounded-lg'
                                />
                            </div>
                            <div>
                                <label className='block text-sm font-medium text-gray-700'>End Date</label>
                                <input
                                    type='date'
                                    name='end_date'
                                    value={selectedPromoter.end_date || ''}
                                    onChange={handleInputChange}
                                    min={new Date().toISOString().split('T')[0]}
                                    className='w-full border border-gray-300 p-2 rounded-lg'
                                />
                            </div>
                            <div>
                                <label className='block text-sm font-medium text-gray-700'>Discount</label>
                                <input
                                    type='number'
                                    name='discount'
                                    value={selectedPromoter.discount || ''}
                                    onChange={handleInputChange}
                                    className='w-full border border-gray-300 p-2 rounded-lg'
                                />
                            </div>
                            <div>
                                <label className='block text-sm font-medium text-gray-700'>Minimum Amount</label>
                                <input
                                    type='number'
                                    name='minimum_purchase'
                                    value={selectedPromoter.minimum_purchase || ''}
                                    onChange={handleInputChange}
                                    className='w-full border border-gray-300 p-2 rounded-lg'
                                />
                            </div>
                            <div>
                                <label className='block text-sm font-medium text-gray-700'>Promoter Point</label>
                                <input
                                    type='number'
                                    name='pr_points'
                                    value={selectedPromoter.pr_points || ''}
                                    onChange={handleInputChange}
                                    className='w-full border border-gray-300 p-2 rounded-lg'
                                />
                            </div>
                            <div>
                                <label className='block text-sm font-medium text-gray-700'>Status</label>
                                <select
                                    name='status'
                                    value={selectedPromoter.status || ''}
                                    onChange={handleInputChange}
                                    className='w-full border border-gray-300 p-2 rounded-lg'
                                >
                                    <option value='active'>Active</option>
                                    <option value='inactive'>Inactive</option>
                                </select>
                            </div>
                            <div className='flex justify-end mt-4'>
                            <button
                                type='submit'
                                className='bg-black text-white px-4 py-2 rounded-lg'
                            >
                                Submit
                            </button>
                            <button
                                type='button'
                                onClick={() => setIsModalOpen(false)}
                                className='ml-2 bg-yellow-500 text-white px-4 py-2 rounded-lg'
                            >
                                Cancel
                            </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {isViewModalOpen && (
                <div className='fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50'>
                    <div className='bg-white p-6 rounded-lg w-96'>
                        <h2 className='text-lg font-bold mb-4'>Promoter Details</h2>
                        <p>Name: {selectedPromoter.firstname} {selectedPromoter.lastname}</p>
                        <p>Bank Name: {selectedPromoter.bankname}</p>
                        <p>Account Number: {selectedPromoter.accountnumber}</p>
                        <p>Account Name: {selectedPromoter.accountname}</p>
                        <p>Twitter: {selectedPromoter.twitter}</p>
                        <p>Instagram: {selectedPromoter.instagram}</p>
                        <p>Facebook: {selectedPromoter.facebook}</p>
                        <p>LinkedIn: {selectedPromoter.linkedin}</p>
                        <p>TikTok: {selectedPromoter.tiktok}</p>
                        <p>Snapchat: {selectedPromoter.snapchat}</p>
                        <p>Code: {selectedPromoter.code}</p>
                        <p>Start Date: {selectedPromoter.start_date ? new Date(selectedPromoter.start_date).toLocaleDateString() : ''}</p>
                        <p>End Date: {selectedPromoter.end_date ? new Date(selectedPromoter.end_date).toLocaleDateString() : ''}</p>
                        <p>Discount: {selectedPromoter.discount}</p>
                        <p>Minimum Amount: ₦{formatPrice(selectedPromoter.minimum_purchase)}</p>
                        <p>Promoter Percentage: {selectedPromoter.pr_points}%</p>
                        <p>Status: {selectedPromoter.status}</p>
                        <button
                            onClick={() => setIsViewModalOpen(false)}
                            className='mt-4 bg-yellow-500 text-white px-4 py-2 rounded-lg'
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Promoters;
