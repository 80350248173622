import { NavLink } from "react-router-dom";
import SideNav from "../SideNav";
import { RxDashboard } from "react-icons/rx";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlinePointOfSale } from "react-icons/md";
import { LuUsers } from "react-icons/lu";

const SalesRepNav = ({ expandedNav, setExpandedNav }) => {

    return (
        <SideNav expandedNav={expandedNav} setExpandedNav={setExpandedNav}>
            <div className="text-gray-500 font-semibold mb-3 mt-6 uppercase text-lg">MENU</div>
            <NavLink 
                className={({ isActive }) => (isActive ? 'text-primary' : '')} 
                to="/sales_rep/dashboard"
            >
                <div className="flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                    <RxDashboard />
                    <span>Dashboard</span>
                </div>
            </NavLink>

            <NavLink 
                className={({ isActive }) => (isActive ? 'text-primary' : '')} 
                to="/sales_rep/sales"
            >
                <div className="flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                    <MdOutlinePointOfSale />
                    <span>Sales</span>
                </div>
            </NavLink>

            <div className="text-gray-500 font-semibold mb-3 mt-6 uppercase text-lg">Others</div>
            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : ' text-gray-800')} to={'/sales_rep/promoter'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                <LuUsers />
                    <span>Promoter</span>
                </div>
            </NavLink>
            <NavLink 
                className={({ isActive }) => (isActive ? 'text-primary' : '')} 
                to="/sales_rep/settings"
            >
                <div className="flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                    <IoSettingsOutline />
                    <span>Settings</span>
                </div>
            </NavLink>
        </SideNav>
    );
};

export default SalesRepNav;
