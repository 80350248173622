import React from 'react'
import Promoter from '../components/Promoters'

const Promoters = () => {
  return (
    <div>
        <Promoter />
    </div>
  )
}

export default Promoters