import { useCallback, useContext, useEffect, useState } from "react";
import { FiLogOut } from "react-icons/fi";
import Modal from "../Modal";
import { useNavigate } from "react-router-dom";
import { AppContext } from '../context/AppContext'

const HeaderDropMenu = ({ showDropdown, toggleDropdown, setLoggedIn }) => {
    const { user, setUser } = useContext(AppContext);
    const Navigate = useNavigate();
    const [userLoggin, setUserLoggin] = useState(true);
    const [showModal, setShowModal] = useState(false)

    const logout = () => {
        setLoggedIn(false)
        setUserLoggin(false)
        setUser(null);
        window.localStorage.removeItem("tripstore_login")
        window.localStorage.removeItem("vendor");
        window.location.reload();
    }
    
    // Start Auto Logout
    const INACTIVITY_TIME = 5 * 60 * 1000; // 5 minutes (adjust as needed)
    let inactivityTimeOut;
    
    const resetTimer = useCallback(() => {
        if (inactivityTimeOut) {
            clearTimeout(inactivityTimeOut);
        }
    
        inactivityTimeOut = setTimeout(() => {
            logout();
        }, INACTIVITY_TIME);
    }, [logout]);
    
    useEffect(() => {
        // Only set up event listeners if user is logged in
        if (userLoggin) {
            const events = ['mousemove', 'keydown', 'click', 'scroll'];
    
            events.forEach((event) => 
                window.addEventListener(event, resetTimer, { passive: true })
            );
    
            resetTimer();
    
            return () => {
                events.forEach((event) => 
                    window.removeEventListener(event, resetTimer)
                );
                
                if (inactivityTimeOut) {
                    clearTimeout(inactivityTimeOut);
                }
            };
        }
    }, [userLoggin, resetTimer]);
    // Auto Logout Ended Here

    return (
        <>
            <div onClick={() => toggleDropdown()} className={`${showDropdown ? " block" : " hidden"} fixed top-0 left-0 w-screen h-screen z-50`}>
                <div onClick={(e) => e.stopPropagation()} className=" absolute top-12 right-6 bg-white shadow-xl rounded-lg py-2">
                    <div onClick={() => setShowModal(true)} className=" flex gap-4 items-center px-6 py-1 hover:bg-slate-500 hover:text-white cursor-pointer">
                        <FiLogOut />
                        <span>Logout</span>
                    </div>
                </div>
            </div>
            {showModal && <Modal setShowModal={setShowModal} title={"LOGOUT"} info={"Proceed to logout"} onProceed={logout} isProceedModal={true} />}
        </>
    )
}

export default HeaderDropMenu