import { useContext, useState } from "react";
import { HiUpload } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { addProducts, getCategories, getAllSizes, convertProductData, getImageUploadLink, vendorAddProducts } from "../../calls/auths";
import Loader from "../../calls/Loader";
import MessageResponse from "../../calls/MessageResponse";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useEffect } from "react";
import Select from 'react-select';
import ImageGalleryPopup from "../../components/ImageGalleryPopup";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const VendorAddProduct = () => {
  const navigate = useNavigate ();
  const { updatenewProducts, user } = useContext(AppContext);

  const [productPic, setProductPic] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiRes, setApiRes] = useState();
  const [apiMsg, setApiMsg] = useState();
  const [inputs, setInputs] = useState({});
  const [categories, setCategories] = useState([]);
  const [sizeTypes, setSizeTypes] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const [product, setProduct] = useState({
    name: '',
    categories: [],
    gender: '',
    brand: '',
    type: '',
    description: '',
    additionalInformation: '',
    shippingInformation: '',
    sizeType: '',
    sizes: [],
    images: [],
    vendor: {
        id: '',
        status: "pending",
        name: '',
    }
  });

  const handleImageSelect = (images) => {
    let imageData = productPic;
    images.forEach(item => {
      imageData.push(item.url);
    });
    setProductPic(imageData);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(productPic);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setProductPic(items);
  };

  const getToken = () => {
    return localStorage.getItem('tripstore_login'); // Retrieve the token from localStorage
  };

  const options = categories.map((category) => ({
    value: category.name,
    label: category.name,
  }));

  const sizeOptions = product.sizeType
    ? sizeTypes.find(sizeType => sizeType.name.toLowerCase() === product.sizeType.toLowerCase())?.sizes || []
    : [];

  const handleInputChange = (e, selectedOptions) => {
    if (selectedOptions) {
      // Handle Select input change
      const selectedValues = selectedOptions.map(option => option.value);
      setProduct((prevProduct) => ({ ...prevProduct, categories: selectedValues }));
    } else {
      // Handle regular input change
      const { name, value } = e.target;
      setProduct((prevProduct) => ({ ...prevProduct, [name]: value }));
      setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
    }
  };

  const handleSizePriceChange = (sizeIndex, e) => {
    const { name, value } = e.target;
    const newSizes = [...product.sizes];
    newSizes[sizeIndex][name] = value;
    setProduct({ ...product, sizes: newSizes });
  };

  const handleSizeChange = (sizeIndex, colorIndex, e) => {
    const { name, value } = e.target;
    const newSizes = [...product.sizes];
    const colorValue = name === 'color' && value === '#000000' ? '#000000' : value;

    newSizes[sizeIndex].colors[colorIndex] = {
      ...newSizes[sizeIndex].colors[colorIndex],
      [name]: colorValue,
    };

    setProduct({ ...product, sizes: newSizes });
  };

  const addSize = () => {
    setProduct({
      ...product,
      sizes: [
        ...product.sizes,
        {
          size: '',
          discount: '',
          costPrice: '',
          price: '',
          weight: '',
          colors: [{ color: '', amount: 0 }],
        },
      ],
    });
  };

  // Function to add color to a size
  const addColor = (sizeIndex) => {
    const newSizes = [...product.sizes];
    const newColor = newSizes[sizeIndex].colors[newSizes[sizeIndex].colors.length - 1].color.trim().toLowerCase();
    const existingColors = newSizes[sizeIndex].colors.map(color => color.color.trim().toLowerCase());

    if (existingColors.filter(color => color === newColor).length > 1) {
      alert('Color already exists. Please enter a different color.');
      return;
    }

    newSizes[sizeIndex].colors.push({ color: '', amount: 0 });
    setProduct({ ...product, sizes: newSizes });
  };

  const removeColor = (sizeIndex, colorIndex) => {
    const newSizes = [...product.sizes];
    newSizes[sizeIndex].colors.splice(colorIndex, 1);
    setProduct({ ...product, sizes: newSizes });
  };

  const removeSize = (index) => {
    const newSizes = [...product.sizes];
    newSizes.splice(index, 1);
    setProduct({ ...product, sizes: newSizes });
  };

  const totalStock = product.sizes.reduce(
    (acc, size) =>
      acc +
      size.colors.reduce((accColor, color) => accColor + parseInt(color.amount), 0),
    0
  );

  // Function to handle removal of image
  const handleRemoveImage = (index) => {
    const newProductPics = productPic.filter((_, i) => i !== index);
    setProductPic(newProductPics);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if categories are selected
    if (!product.categories || product.categories.length === 0) {
      alert('Please select at least one category.');
      return;
    }

    // Check if any discount is greater than 100 or less than 0
    for (const size of product.sizes) {
      if (size.discount && (size.discount > 100 || size.discount < 0)) {
        alert('Discount must be between 0% and 100%.');
        return;
      }
    }

    setIsLoading(true);

    const token = window.localStorage.getItem("tripstore_login");


    // Create a new product object with images
    const updatedProduct = {
      ...product,
      images: productPic,
      vendor: {
        id: user._id,
        status: "pending",
        name: `${user.firstname} ${user.lastname}`,
      }
    };

    // console.log(updatedProduct);

    const response = await vendorAddProducts(updatedProduct, token);

    setApiRes(response);
    setApiMsg(response.data.message ? response.data.message : "Products Added Successfully");
    setIsLoading(false);

    if (!response.data.message) {
      updatenewProducts(response.data);
    }

    setTimeout(() => {
      setApiRes();
      setApiMsg();
      window.location.reload();
    //   navigate('/vendor/inventory/barcode');
    }, 5000);
  };



  useEffect(() => {
    const fetchCategories = async () => {
      const token = getToken();
      if (!token) {
        console.error('No token provided');
        return;
      }

      const result = await getCategories(token);
      console.log('API Result:', result);

      if (result.status === 200) {
        // Access the categories directly from result.data
        const fetchedCategories = result.data;
        console.log('Categories:', fetchedCategories);
        setCategories(Array.isArray(fetchedCategories) ? fetchedCategories : []);
      } else {
        console.error('Error fetching categories:', result.data);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchSizes = async () => {
      const token = localStorage.getItem('tripstore_login');
      const response = await getAllSizes(token);
      if (response.status === 200) {
        setSizeTypes(response.data);
      } else {
        console.error('Failed to fetch sizes:', response.data);
      }
    };
    fetchSizes();
  }, []);

  return (
    <>
      <div className="p-4">
        <form onSubmit={handleSubmit} className="w-full my-4" encType="multipart/form-data">
          {isLoading && <Loader className="w-full h-full" />}
          {apiRes && <MessageResponse response={apiRes} msg={apiMsg} />}
          <div className="p-4 md:p-8 bg-white rounded-md shadow border-2 border-slate-300">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="productPic" direction="horizontal">
              {(provided) => (
                <div
                  className="pb-2 flex items-center flex-none gap-4 overflow-x-auto scrollbar small-scrollbar"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {productPic.map((item, index) => (
                    <Draggable key={index} draggableId={`${index}`} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="flex items-center"
                        >
                          <div className="w-[200px]">
                            <div className="w-full aspect-square rounded-md bg-gray-300 shadow-lg overflow-hidden">
                              {item && <img src={item} className="w-full h-full object-contain" alt="Not found" />}
                            </div>
                            <div className="mt-4 flex gap-2">
                              <div onClick={() => handleRemoveImage(index)} className="cursor-pointer rounded-md border-2 border-red-500 px-3 text-red-500 hover:bg-red-500 hover:text-white flex justify-center items-center active:scale-95">
                                <IoClose />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <div onClick={() => setIsPopupOpen(true)} className="w-[60px] h-[60px] rounded-full border-2 border-tertiary mx-8 cursor-pointer flex justify-center items-center text-3xl transition hover:rotate-180 hover:bg-tertiary hover:text-white active:scale-95">
                    <HiUpload />
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
          </div>
          <div className="p-4 md:p-8 bg-white rounded-md shadow border-2 border-slate-300 mt-2">
            <div className="flex flex-col md:flex-row gap-4 w-full">
              <div className="flex flex-col w-full">
                <label className="text-sm font-semibold" htmlFor="p-name">Product Name</label>
                <input type="text" name="name" id="p-name" onChange={handleInputChange} required />
              </div>
              <div className="flex flex-col w-full">
                <label className="text-sm font-semibold" htmlFor="p-category">Product Category</label>
                <Select
                  name="categories"
                  id="p-category"
                  value={options.filter(option => product.categories.includes(option.value))}
                  onChange={(selectedOptions) => handleInputChange(null, selectedOptions)}
                  options={options}
                  isMulti
                  classNamePrefix="react-select"
                  required
                />
              </div>
              <div className="flex flex-col w-full">
                <label className="text-sm font-semibold" htmlFor="gender">Gender</label>
                <select name="gender" id="p-gender" onChange={handleInputChange} required>
                  <option value="">---</option>
                  {Object.values(gender).map((item, index) => (
                    <option key={index} value={item} className="capitalize">{item}</option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col w-full">
                <label className="text-sm font-semibold" htmlFor="b-name">Brand Name</label>
                <input type="text" name="brand" id="b-name" onChange={handleInputChange} required />
              </div>
              <div className="flex flex-col w-full">
                <label className="text-sm font-semibold" htmlFor="b-type">Product Type</label>
                <select name="type" id="b-type" onChange={handleInputChange} required>
                  <option value="">---</option>
                  {Object.values(type).map((item, index) => (
                    <option key={index} value={item} className="capitalize">{item}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-4 w-full">
              <div className="flex flex-col w-full">
                <label className="text-sm font-semibold" htmlFor="p-desc">Product Description</label>
                <textarea name="description" id="p-desc" onChange={handleInputChange}></textarea>
              </div>
              <div className="flex flex-col w-full">
                <label className="text-sm font-semibold" htmlFor="a-info">Additional Information</label>
                <textarea name="additionalInformation" id="a-info" onChange={handleInputChange}></textarea>
              </div>
              <div className="flex flex-col w-full">
                <label className="text-sm font-semibold" htmlFor="s-info">Shipping Information</label>
                <textarea name="shippingInformation" id="s-info" onChange={handleInputChange}></textarea>
              </div>
            </div>
          </div>
          <div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Size Type</label>
              <select
                name="sizeType"
                value={product.sizeType}
                onChange={handleInputChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                required
              >
                <option value="">Select Size Type</option>
                {sizeTypes.map((sizeType) => (
                  <option key={sizeType._id} value={sizeType.name}>{sizeType.name}</option>
                ))}
              </select>
            </div>

            {product.sizes.map((size, sizeIndex) => (
              <div key={sizeIndex} className="flex flex-col space-y-4">
                <div className="flex flex-col space-x-2 mt-2">
                  <div className="flex flex-col md:flex-row w-full md:space-x-3">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Size</label>
                      <select
                        name="size"
                        value={size.size}
                        onChange={(e) => {
                          const newSizes = [...product.sizes];
                          newSizes[sizeIndex].size = e.target.value;
                          setProduct({ ...product, sizes: newSizes });
                        }}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                        required
                      >
                        <option value="">Select Size</option>
                        {sizeOptions.map((sizeOption) => (
                          <option key={sizeOption._id} value={sizeOption.name}>
                            {sizeOption.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col mt-3 md:mt-0">
                      <label className="block text-sm font-medium text-gray-700">Cost Price</label>
                      <input
                        type="number"
                        name="costPrice"
                        value={size.costPrice}
                        onChange={(e) => handleSizePriceChange(sizeIndex, e)}
                        className="block w-full border-gray-300 rounded-md shadow-sm"
                        placeholder="Cost Price"
                        required
                      />
                    </div>
                    <div className="flex flex-col mt-3 md:mt-0">
                      <label className="block text-sm font-medium text-gray-700">Price</label>
                      <input
                        type="number"
                        name="price"
                        value={size.costPrice}
                        onChange={(e) => handleSizePriceChange(sizeIndex, e)}
                        className="block w-full border-gray-300 rounded-md shadow-sm"
                        placeholder="Price"
                        required
                        readOnly
                      />
                    </div>
                    <div className="flex flex-col mt-3 md:mt-0">
                      <label className="text-sm font-semibold" htmlFor={`discount-${sizeIndex}`}>Discount(%)</label>
                      <input
                        type="number"
                        min={0}
                        name="discount"
                        id={`discount-${sizeIndex}`}
                        value={0}
                        // value={size.discount}
                        onChange={(e) => handleSizePriceChange(sizeIndex, e)}
                        className="block w-full border-gray-300 rounded-md shadow-sm"
                        placeholder="Discount"
                        readOnly
                      />
                    </div>
                    <div className="flex flex-col mt-3 md:mt-0">
                      <label className="text-sm font-semibold" htmlFor={`weight-${sizeIndex}`}>Weight(KG)</label>
                      <input
                        type="text"
                        name="weight"
                        id={`weight-${sizeIndex}`}
                        value={size.weight}
                        onChange={(e) => handleSizePriceChange(sizeIndex, e)}
                        className="block w-full border-gray-300 rounded-md shadow-sm"
                        placeholder="Weight (KG)"
                      />
                    </div>
                  </div>
                  {size.colors.map((color, colorIndex) => (
                    <div key={colorIndex} className="flex md:flex-row space-x-3">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Product Color</label>
                        <input
                          type="color"
                          name="color"
                          value={color.color}
                          onChange={(e) => handleSizeChange(sizeIndex, colorIndex, e)}
                          className="p-1 h-10 w-14 block bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none"
                          placeholder="Color"
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">No in stock</label>
                        <input
                          type="number"
                          name="amount"
                          value={color.amount}
                          onChange={(e) => handleSizeChange(sizeIndex, colorIndex, e)}
                          className="block w-full border-gray-300 rounded-md shadow-sm"
                          placeholder="Amount"
                          required
                        />
                      </div>
                      
                      {(colorIndex > 0 || size.colors.length > 1) && (
                        <button
                          type="button"
                          onClick={() => removeColor(sizeIndex, colorIndex)}
                          className="mt-4 px-2 bg-red-700 text-white rounded-md"
                        >
                          Remove Color
                        </button>
                      )}
                    </div>
                  ))}
                </div>
                <div className="space-x-3">
                  <button
                    type="button"
                    onClick={() => addColor(sizeIndex)}
                    className="mt-2 px-4 py-2 bg-secondary text-white rounded-md"
                  >
                    Add Color
                  </button>
                  {(sizeIndex > 0 || product.sizes.length > 1) && (
                    <button
                      type="button"
                      onClick={() => removeSize(sizeIndex)}
                      className="mt-2 px-4 py-2 bg-red-700 text-white rounded-md"
                    >
                      Remove Size
                    </button>
                  )}
                </div>
              </div>
            ))}
            <button
              type="button"
              onClick={addSize}
              className="mt-2 px-4 py-2 bg-primary text-white rounded-md"
            >
              Add Size
            </button>
            <div>
              <label className="block text-sm font-medium text-gray-700">Total Stock</label>
              <input
                type="number"
                value={totalStock}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                readOnly
              />
            </div>
          </div>
          <button className="w-full bg-tertiary text-white" type="submit">Save Product</button>
        </form>
      </div>
      <ImageGalleryPopup isOpen={isPopupOpen} onClose={closePopup} onImageSelect={handleImageSelect} multiSelect={true} />
    </>
  );
};

export default VendorAddProduct;

const type = {
  cloth: 'cloth',
  shoe: 'shoe',
  necklace: 'necklace',
  wristwatch: 'wristwatch',
  bag: 'bag',
  bracelet: 'bracelet'
};

const gender = {
  all: 'all',
  male: 'male',
  female: 'female',
  unisex: 'unisex',
};
