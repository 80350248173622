import { useEffect, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import Logo from '../../assets/logo-bg.png';
import { useNavigate } from "react-router-dom";
import { getAllVendorsProducts, getProducts } from "../../calls/auths";

const VendorProducts = () => {
    const navigate = useNavigate();
    
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [sliceCount, setSliceCount] = useState({ from: 0, to: 20 });
    const [showCatDropDown, setShowCatDropDown] = useState(false);
    const [searchInput, setSearchInput] = useState('');

    const toggleShowCatDropDown = () => {
        setShowCatDropDown(!showCatDropDown);
    };

    const addSlice = () => {
        if (sliceCount.to >= filteredProducts.length) {
            setSliceCount({ from: sliceCount.from, to: sliceCount.to });
        } else {
            setSliceCount({ from: sliceCount.to, to: sliceCount.to + 20 });
            document.querySelector('#table-container')?.scrollTo(0, 0);
        }
    };

    const cutSlice = () => {
        if (sliceCount.from <= 0) {
            setSliceCount({ from: sliceCount.from, to: sliceCount.to });
        } else {
            setSliceCount({ from: sliceCount.from - 19, to: sliceCount.from });
            document.querySelector('#table-container')?.scrollTo(0, 0);
        }
    };

    const calculateTotalQuantity = (product) => {
        return product.measurements.reduce((total, measurement) => {
            return total + measurement.colors.reduce((sum, color) => sum + color.amount, 0);
        }, 0);
    };

    // const handleSearchInputChange = (event) => {
    //     setSearchInput(event.target.value);
    //     const filtered = products.filter(product => {
    //         const categoryNames = Array.isArray(product.category)
    //             ? product.category.map(cat => cat.name).join(' ').toLowerCase()
    //             : product.category ? product.category.toLowerCase() : '';

    //         return (
    //             product.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
    //             categoryNames.includes(event.target.value.toLowerCase()) ||
    //             product.gender.toLowerCase().includes(event.target.value.toLowerCase())
    //         );
    //     });
    //     setFilteredProducts(filtered);
    //     setSliceCount({ from: 0, to: 19 });
    // };

    const handleSearchInputChange = (event) => {
      const inputValue = event.target.value || ''; // Ensure input value is a string
      setSearchInput(inputValue);
  
      const filtered = products.filter((product) => {
          const name = product.name ? product.name.toLowerCase() : ''; // Safeguard product.name
          const gender = product.gender ? product.gender.toLowerCase() : ''; // Safeguard product.gender
          const categoryNames = Array.isArray(product.category)
              ? product.category.map((cat) => cat.name || '').join(' ').toLowerCase() // Safeguard category names
              : product.category
              ? product.category.toLowerCase()
              : '';
  
          return (
              name.includes(inputValue.toLowerCase()) || // Search in product name
              categoryNames.includes(inputValue.toLowerCase()) || // Search in category names
              gender.includes(inputValue.toLowerCase()) // Search in product gender
          );
      });
  
      setFilteredProducts(filtered);
      setSliceCount({ from: 0, to: 20 });
  };
  

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const loginSession = window.localStorage.getItem("tripstore_login")
                const response = await getAllVendorsProducts(loginSession);
                const fetchedProducts = response.data || [];
                setProducts(Array.isArray(fetchedProducts) ? fetchedProducts : []);
                setFilteredProducts(Array.isArray(fetchedProducts) ? fetchedProducts : []);
            } catch (error) {
                console.error('Error fetching products:', error);
                setProducts([]);
                setFilteredProducts([]);
            }
        };

        fetchProducts();
    }, []);

    return (
        <>
            <div className="h-[20vh] sm:h-[10vh] flex justify-center items-center sm:items-end">
                <div className="w-[95%] flex flex-col-reverse gap-2 sm:flex-row justify-between items-center">
                    <form action="" className="relative inline-block">
                        <input 
                            type="text" 
                            className="w-full py-2 px-4 pr-12" 
                            placeholder="Search Product" 
                            value={searchInput} 
                            onChange={handleSearchInputChange}
                        />
                        <button className="absolute right-0 top-[50%] -translate-y-[50%]" type="submit"><IoSearch /></button>
                    </form>
                </div>
            </div>
            <div className="h-[60vh] sm:h-[70vh] flex justify-center items-start pt-6">
  <div id="products-container" className="w-[95%] max-h-[95%] border-2 border-slate-300 rounded-md overflow-y-auto small-scrollbar scrollbar">
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 p-4">
      {filteredProducts.length > 0 ? (
        filteredProducts?.slice(sliceCount.from, sliceCount.to)?.map((item, index) => (
          <div 
            onClick={() => navigate(`/admin/inventory/detail/${item._id}`)} 
            key={index} 
            className="flex flex-col justify-between items-center p-4 border rounded-md hover:shadow-lg cursor-pointer"
          >
            <div className="w-60">
            <img 
              src={item.images?.[0] ?? Logo} 
              alt={item.name} 
              className="w-full h-full object-cover rounded-md"
              />
            </div>
            <div className="mt-5 text-center">
              <p className="font-semibold text-lg">{item.name}</p>
              <p className="text-sm text-gray-500">Gender: {item.gender}</p>
              <p className="text-sm text-gray-500">
                Category: {Array.isArray(item.category) 
                  ? item.category.map(cat => cat.name).join(', ') 
                  : item.category
                }
              </p>
              <p className="text-sm text-gray-500">Qty: {calculateTotalQuantity(item)}</p>
              <p className="text-sm text-gray-500">Created: {formatDate(item.createdAt)}</p>
              <p className="text-sm text-gray-500">Updated: {formatDate(item.updatedAt)}</p>
            </div>
          </div>
        ))
      ) : (
        <div className="col-span-full text-center text-gray-500 font-semibold">
          No product
        </div>
      )}
    </div>
  </div>
</div>

<div className="h-[10vh] w-[95%] m-auto flex justify-between items-center">
  <button onClick={cutSlice} className="flex items-center gap-4 hover:bg-slate-200">
    <FaAngleLeft />
    <span className="hidden md:inline">Previous</span>
  </button>
  <div className={`${filteredProducts.length <= 0 && "hidden"} text-sm font-semibold text-gray-500`}>
    Showing {1 + sliceCount.from} to {sliceCount.to >= filteredProducts.length ? filteredProducts.length : sliceCount.to} of {filteredProducts.length}
  </div>
  <button onClick={addSlice} className="flex items-center gap-4 hover:bg-slate-200">
    <span className="hidden md:inline">Next</span>
    <FaAngleRight />
  </button>
</div>
            {showCatDropDown && <div onClick={toggleShowCatDropDown} className="absolute top-0 left-0 w-full h-full bg-secondary/10 z-10"></div>}
        </>
    );
};

export default VendorProducts;
