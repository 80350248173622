import React from "react";

const Profile = ({ profileData }) => {
  const {
    firstname,
    lastname,
    email,
    businessName,
    businessAddress,
    businessPhone,
    businessIGpage,
    whatsappNumber,
    isAccountVerified,
    status,
    image,
    createdAt,
  } = profileData;

  const defaultAvatar =
    "https://avatar.iran.liara.run/public"; // Default demo avatar

  return (
    <div className="p-6 bg-gray-50 min-h-screen md:min-w-[700px] flex items-center justify-center">
      <div className="w-full max-w-4xl bg-white rounded-lg shadow-lg p-6">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h2 className="text-2xl font-semibold text-gray-800">Profile</h2>
            <p className="text-sm text-gray-500">
              Member since: {new Date(createdAt).toLocaleDateString()}
            </p>
          </div>
          <div>
            <img
              src={image || defaultAvatar}
              alt={`${firstname} ${lastname}`}
              className="w-24 h-24 rounded-full object-cover border-2 border-gray-300"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col gap-2">
            <p className="text-gray-600">
              <span className="font-semibold text-gray-800">Full Name:</span>{" "}
              {firstname} {lastname}
            </p>
            <p className="text-gray-600">
              <span className="font-semibold text-gray-800">Email:</span>{" "}
              {email}
            </p>
            <p className="text-gray-600">
              <span className="font-semibold text-gray-800">
                Business Name:
              </span>{" "}
              {businessName}
            </p>
            <p className="text-gray-600">
              <span className="font-semibold text-gray-800">
                Business Phone:
              </span>{" "}
              {businessPhone}
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-gray-600">
              <span className="font-semibold text-gray-800">
                Business Address:
              </span>{" "}
              {businessAddress}
            </p>
            <p className="text-gray-600">
              <span className="font-semibold text-gray-800">
                Instagram Page:
              </span>{" "}
              {businessIGpage}
            </p>
            <p className="text-gray-600">
              <span className="font-semibold text-gray-800">
                WhatsApp Number:
              </span>{" "}
              {whatsappNumber}
            </p>
            <p className="text-gray-600">
              <span className="font-semibold text-gray-800">Status:</span>{" "}
              <span
                className={`px-3 py-1 rounded-full text-sm ${
                  status === "active"
                    ? "bg-green-100 text-green-600"
                    : "bg-red-100 text-red-600"
                }`}
              >
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </span>
            </p>
          </div>
        </div>
        <div className="mt-6 border-t pt-4">
          <p className="text-gray-600">
            <span className="font-semibold text-gray-800">
              Account Verified:
            </span>{" "}
            <span
              className={`px-3 py-1 rounded-full text-sm ${
                isAccountVerified
                  ? "bg-green-100 text-green-600"
                  : "bg-red-100 text-red-600"
              }`}
            >
              {isAccountVerified ? "Yes" : "No"}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Profile;
