import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import UserDashboard from "./screens/user/UserDashboard"
import UserOrder from "./screens/user/UserOrder"
import { useContext, useEffect, useState } from "react"
import UserNav from "./components/user/UserNav"
import Header from "./components/Header"
import { AppContext } from "./context/AppContext"
import Settings from "./screens/Settings"
import { jwtDecode } from "jwt-decode"
import OrderDetail from "./screens/user/Orderdetails"
import ZaddyList from "./screens/user/ZaddyList"
import UserReview from "./screens/user/UserReview"
import UserWishList from "./screens/user/UserWishList"
import ReviewDetail from "./screens/user/ReviewDetails"
import Promoter from "./screens/user/Promoter"

const User = () => {
    const { setLoggedIn } = useContext(AppContext);
    const location = useLocation();
    const navigate = useNavigate();

    const [headerTitle, setHeaderTitle] = useState('');
    const [expandedNav, setExpandedNav] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleTitleChange = () => {
        switch (location.pathname) {
            case '/user/dashboard':
                setHeaderTitle('My Account');
                break;
            case '/user/order':
                setHeaderTitle('Orders');
                break;
            case '/user/zaddylist':
                setHeaderTitle('Zaddy Pay');
                break;
            case '/user/details':
                setHeaderTitle('Order Details');
                break;
            case '/user/review':
                setHeaderTitle('Reviews');
                break;
                case '/user/review/details':
                    setHeaderTitle('Product Review');
                    break;
                case '/user/wishlist':
                    setHeaderTitle('My Wishlist');
                    break;
                case '/user/promoter':
                    setHeaderTitle('Promoter');
                    break;
            case '/user/settings':
                setHeaderTitle('Settings');
                break;
            default:
                setHeaderTitle('User');
                break;
        }
    };

    useEffect(() => {
        const handleLogin = async () => {
            const loginSession = window.localStorage.getItem("tripstore_login");
      
            if (loginSession && loginSession !== "") {
                const userType = jwtDecode(loginSession);
                const isAdmin = userType.user_type === "admin";
                const isSalesRep = userType.user_type === "sales_rep";
                if (isAdmin) {
                    navigate("/admin/dashboard");
                } else if(isSalesRep) {
                    navigate("/sales_rep/dashboard");
                }
            } else {
                navigate('/login')
            }
          };
      
          handleLogin();
        handleTitleChange();
    }, [location.pathname]);

    return (
        <div className="w-full h-screen md:flex relative">
            <UserNav expandedNav={expandedNav} setExpandedNav={setExpandedNav} />
            <div onClick={() => setExpandedNav(false)} className={`${expandedNav ? "block" : "hidden"} md:hidden fixed w-screen h-screen z-10`}></div>
            <div className="h-full overflow-y-auto overflow-x-hidden md:flex-1 scrollbar relative">
                <Header setExpandedNav={setExpandedNav} title={headerTitle} setLoggedIn={setLoggedIn} />
                <Routes>
                    <Route element={<UserDashboard />} path="/dashboard" />
                    <Route element={<UserOrder />} path="/order" />
                    <Route element={<ZaddyList />} path="/zaddylist" />
                    <Route element={<UserReview />} path="/review" />
                    <Route element={<ReviewDetail />} path="/review/details" />
                    <Route element={<UserWishList />} path="/wishlist" />
                    <Route element={<OrderDetail />} path="/details" />
                    <Route element={<Promoter />} path="/promoter" />
                    <Route element={<Settings userType={'User'} />} path="/settings" />
                </Routes>
            </div>
        </div>
    );
};

export default User;
