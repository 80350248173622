import React, { useContext, useEffect, useState } from 'react';
import { getWishlistAndProduct, removeFromWishlist } from '../../calls/auths';
import { AppContext } from '../../context/AppContext';
import { ToastComponent } from '../../components/ToastComponent';

const UserWishList = () => {
  const { user, wishlist, setWishlist } = useContext(AppContext);
  const [wishlistProducts, setWishlistProducts] = useState([]);
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    async function fetchWishlist() {
      if (user && user._id) {
        try {
          const result = await getWishlistAndProduct(user._id);

          if (!result || !result.data || !result.data.items) {
            console.error('Error: Unable to fetch wishlist data');
            return;
          }

          const items = result.data.items.map(item => {
            let totalStock = 0;

            item.productDetails.measurements.forEach(measurement => {
              measurement.colors.forEach(color => {
                totalStock += color.amount;
              });
            });

            return {
              img: item.productDetails.images[0] || '', // Handle case where there might not be an image
              title: item.productDetails.name,
              price: item.productDetails.measurements[0].price,
              available: totalStock > 0,
              productDetails: item.productDetails
            };
          });

          setWishlistProducts(items);
        } catch (error) {
          console.error('Error fetching wishlist:', error);
          // Handle error state or show error message
        }
      }
    }
    fetchWishlist();
  }, [user]);

  async function removeFromWishlistHandler(product) {
    const userId = user._id;
    const productId = product._id;

    const response = await removeFromWishlist({ userId, productId });

    if (response.status === 200) {
      setWishlist(wishlist - 1);
      // Remove the item from wishlistProducts state
      setWishlistProducts(prevProducts => prevProducts.filter(item => item.productDetails._id !== productId));

      setToastMessage('Product removed from wishlist!');
      setShowToast(true);
    }
  }

  return (
    <div className="p-6 max-w-4xl mx-auto bg-white rounded-xl shadow-md space-y-4">
      <h2 className="text-xl font-semibold">Saved Items ({wishlist})</h2>
      {Array.isArray(wishlistProducts) && wishlistProducts.map((item, index) => (
        <div key={index} className="flex items-center border-b border-gray-200 py-4">
          <img
            src={item.img}
            alt={item.title}
            className="w-24 h-24 object-cover rounded"
          />
          <div className="ml-4 flex-1">
            <p className="font-medium">{item.title}</p>
            {item.variation && (
              <p className="text-sm text-gray-500">Variation: {item.variation}</p>
            )}
            {item.size && (
              <p className="text-sm text-gray-500">Size: {item.size}</p>
            )}
            <div className="mt-2 flex items-center">
              {item.oldPrice && (
                <p className="text-sm text-gray-500 line-through mr-2">
                  {item.oldPrice}
                </p>
              )}
              {item.discount && (
                <p className="text-sm text-red-500">{item.discount}</p>
              )}
              <p className="text-lg font-bold ml-4">&#8358;{item.price.toLocaleString()}</p>
            </div>
          </div>
          <div className="ml-4 flex items-center space-x-4">
            {item.available ? (
              <a href={`/product/${wishlistProducts[index].productDetails._id}/${wishlistProducts[index].productDetails.name}`}>
                <button className="bg-primary text-white px-4 py-2 rounded">
                  BUY NOW
                </button>
              </a>
            ) : (
              <button className="bg-gray-500 text-white px-4 py-2 rounded" disabled>
                OUT OF STOCK
              </button>
            )}
            <button className="text-secondary flex items-center" onClick={() => removeFromWishlistHandler(item.productDetails)}>
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
              <span className="ml-2">REMOVE</span>
            </button>
          </div>
        </div>
      ))}
      {showToast && <ToastComponent success message={toastMessage} onClose={() => setShowToast(false)} />}
    </div>
  );
};

export default UserWishList;
