import React, { useEffect, useState } from 'react';
import './loads.css'; // Assuming styles are in a module file

const Loader = ({ width, right, duration = 30000 }) => {
  const [progress, setProgress] = useState(0);
  const [loadingComplete, setLoadingComplete] = useState(false);

  useEffect(() => {
    let interval;
    if (progress < 100) {
      interval = setInterval(() => {
        setProgress((prev) => Math.min(prev + 1, 100));
      }, duration / 100); // Spread duration evenly over 100 steps
    } else {
      setLoadingComplete(true);
    }
    return () => clearInterval(interval);
  }, [progress, duration]);

  const style = {
    maxWidth: width,
    right: right,
    left: right && 'auto',
  };

  if (!loadingComplete) {
    return (
      <div className="overlay" style={style}>
        {/* Progress Bar */}
        <div className="loader">
          <div className="progress-bar">
            <div
              className="progress-fill"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          <span className="progress-text">{progress}%</span>
        </div>
      </div>
    );
  }

  return (
    <div className="overlay" style={style}>
      {/* Progress Bar */}
      <div className="loader">
        <div className="progress-bar">
          <div
            className="progress-fill"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <span className="progress-text">{progress}%</span>
      </div>
    </div>
  );
};

export default Loader;

