import { LuPhone } from "react-icons/lu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/ic.png";
import { BsSearch } from "react-icons/bs";
import {
  FaRegHeart,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import { AiOutlineMenu } from "react-icons/ai";
import { SiGnuprivacyguard } from "react-icons/si";
import { IoCloseOutline } from "react-icons/io5";
import { FaArrowRightLong } from "react-icons/fa6";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { removeFromCart, getCategories } from "../calls/auths";
import { replacer } from "./product/YouMayLike";

const Top = () => {
  const [showCat, setShowCat] = useState(false);
  const [categories, setCategories] = useState([]);

  const toggleShowCat = () => {
    setShowCat(!showCat);
  };
  const {
    user,
    wishlist,
    getCartCount,
    setCart,
    cart,
    cartArray,
    productInformation,
    setProductInformation,
  } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const [showSideBar, setShowSideBar] = useState(false);
  const [showCartDropdown, setShowCartDropdown] = useState(false);
  const [isHome, setIsHome] = useState(false);
  const [searchValue, setSearchValue] = useState(""); // State for search input

  const handleContainer = () => {
    if (location.pathname === "/") {
      setIsHome(true);
    } else {
      setIsHome(false);
    }
  };

  useEffect(() => {
    handleContainer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleSearchSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission
    navigate(`/search?name=${searchValue}`); // Programmatically navigate to the search page
  };
  const fetchCategories = async () => {
    try {
      const result = await getCategories();
      //   console.log('API Result:', result);

      if (result.status === 200) {
        const fetchedCategories = result.data;
        setCategories(
          Array.isArray(fetchedCategories) ? fetchedCategories : []
        );
      } else {
        console.error("Error fetching categories:", result.data);
      }
    } catch (error) {
      console.error("Error in fetchCategories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const name = params.get("name");
    if (name) {
      setSearchValue(name);
    }
  }, [location.search]);

  useEffect(() => {
    if (searchValue.length >= 3) {
      navigate(`/search?name=${searchValue}`);
    }
  }, [searchValue, navigate]);

  async function removeFromCartFunction(index) {
    if (user) {
      const userId = user._id;
      const productId = productInformation[index].product.productId;
      const colorId = productInformation[index].product.colorId;
      const response = await removeFromCart({ userId, productId, colorId });
      if (response.status === 200) {
        getCartCount();
      }
    } else {
      const localStorageCart = JSON.parse(localStorage.getItem("cart")) || [];
      if (localStorageCart.length > 0) {
        // Remove the item at the specified index
        localStorageCart.splice(index, 1);
        // Update the local storage with the new array
        localStorage.setItem("cart", JSON.stringify(localStorageCart));
        getCartCount();
      } else {
        getCartCount();
      }
    }
  }

    const totalPrice = productInformation.reduce((total, item) => total + item.product?.price * item.quantity, 0);
    return (
        <>
            <div className="">
                <div className={`${isHome ? ' app-container' : ' app-container'}`}>
                    <div className=" flex items-center justify-between border-b-[1px] border-gray-300 text-gray-500 text-sm py-2">
                        <a href={`tel:+2349076600058`} className=" flex items-center gap-2 hover:text-primary"><LuPhone /><span>+234 907 660 0058</span></a>
                        <Link to={user ? `/${user.user_type}/dashboard` : '/login'}>
    <div className="hover:text-primary flex items-center">
        {user ? user.firstname : <>Sign in <SiGnuprivacyguard className="mx-2"/></>}
    </div>
</Link>
                    </div>
                    <div className=" py-2 flex justify-between items-center">
                        <div
                        onClick={toggleShowCat}
                        className={`${showCat ? 'bg-white' : ''} hidden relative transition duration-500 h-full w-[30%] lg:w-[25%] 2xl:w-[15%] group md:flex items-center hover:bg-white cursor-pointer`}
                    >
                        <div className="w-full flex items-center justify-between px-2 py-4">
                            <div className="h-full flex gap-4 items-center font-semibold">
                                <AiOutlineMenu />
                                <span>Categories</span>
                            </div>
                        </div>
                        <div className={`${showCat ? 'top-[50px] opacity-100 visible' : 'top-0 opacity-0 invisible'} transition-all duration-500 absolute left-0 z-20 w-full shadow-md border-[1px] border-slate-300 bg-white divide-y-[1px] divide-slate-300`}>
  {/* Add "All" category manually */}
  <a
    key="all"
    href="/search?category="
    className="capitalize py-2 px-4 truncate block text-sm hover:bg-slate-200 text-gray-700 hover:text-primary"
  >
    All
  </a>
  {/* Map through the categories from backend */}
  {categories.map((category) => (
    <a
      key={category._id}
      href={`/search?category=${encodeURIComponent(category.name)}`}
      className="capitalize py-2 px-4 truncate block text-sm hover:bg-slate-200 text-gray-700 hover:text-gray-300 md:hover:text-primary"
    >
      {category.name}
    </a>
  ))}
</div>

                    </div>
                        <div className=" flex items-center gap-4">
                            <div onClick={() => setShowSideBar(true)} className=" md:hidden text-xl cursor-pointer"><AiOutlineMenu /></div>
                            <Link to={'/'}>
                                <div className=" h-[80px]">
                                    <img src={Logo} className=' h-full' alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className=" hidden md:block flex-1 px-8">
                            {/* Search for big screen */}
                            <form onSubmit={handleSearchSubmit} className=' w-full max-w-[800px] m-auto flex items-center justify-center'>
                                <input 
                                    type="text" 
                                    name="search" 
                                    placeholder='Search product' 
                                    className=' flex-1 h-[40px] rounded-l rounded-r-none bg-white border-[1px] border-primary focus:border-primary text-tertiary'
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}  // Update searchValue state on change
                                    autoFocus  // Ensure the text field is active
                                />
                                <button className=' text-white bg-primary/90 hover:bg-primary rounded-r rounded-l-none h-[40px] px-6' type="submit"><BsSearch /></button>
                            </form>
                        </div>
                        <div className=" flex items-center gap-8">
                            <Link to={user ? '/wishlist' : '/login' } className=" relative flex flex-col items-center text-xl group">
                                <FaRegHeart className=" group-hover:text-primary" />
                                <span className=" text-gray-400 text-xs group-hover:text-primary">Wishlist</span>
                                <div className=" absolute -top-1 -right-1 w-[14px] aspect-square rounded-full bg-primary flex justify-center items-center overflow-hidden">
                                    <div className=" text-[10px] leading-[10px] text-white">{ wishlist }</div>
                                </div>
                            </Link>
                            <div onMouseEnter={() => setShowCartDropdown(true)} onMouseLeave={() => setShowCartDropdown(false)} className=" relative flex flex-col items-center text-xl group cursor-pointer">
                                <FiShoppingCart className=" group-hover:text-primary" />
                                <span className=" text-gray-400 text-xs group-hover:text-primary">Cart</span>
                                <div className=" absolute -top-1 -right-1 w-[14px] aspect-square rounded-full bg-primary flex justify-center items-center overflow-hidden">
                                    <div className=" text-[10px] leading-[10px] text-white">{cart}</div>
                                </div>
                                <div className={`${showCartDropdown ? " top-10 opacity-100 visible" : " top-6 opacity-0 invisible"} transition-all duration-500 absolute z-50 right-0 w-[300px] p-4 bg-white shadow-lg rounded cursor-default`}>
                                    {
                                        productInformation && productInformation.length > 0 && productInformation.map((item, index) => (
                                            <div className=" flex items-center gap-2 py-2 border-b-[1px] border-slate-300" key={index}>
                                                <div className=" flex-1">
                                                    <a href={`/product/${item._id}/`} className=" text-sm font-semibold hover:text-primary">{item.product.name}</a>
                                                    <div className=" text-gray-400 text-xs">
                                                        { 
                                                            user && cartArray[index] && cartArray[index].quantity ? 
                                                            `${cartArray[index].quantity} x ` : `${productInformation[index].quantity} x `
                                                        }
                                                        &#8358;{item.product.price.toLocaleString() }
                                                    </div>
                                                </div>
                                                <a href={`/product/${item._id}/`} className=" w-[60px] aspect-square rounded-sm border-[1px] border-slate-300 overflow-hidden">
                                                    <img src={item.product.images[0] ?? Logo} className=" w-full h-full object-cover" alt="" />
                                                </a>
                                                <button onClick={() => removeFromCartFunction(index)} className=" cursor-pointer text-gray-400 hover:text-gray-800"><IoCloseOutline /></button>
                                            </div>
                                        ))
                                    }
                                    <div className=" mt-2 flex items-center justify-between">
                                        <div className="text-sm">TOTAL</div>
                                        <div className="text-sm">&#8358; {totalPrice.toLocaleString()}</div>
                                    </div>
                                    <div className=" mt-2 flex *:w-full gap-4">
                                        <a href="/cart" className=" py-2 text-xs font-semibold rounded-sm bg-primary/90 hover:bg-primary text-center text-white">View Cart</a>
                                        <a href="/checkout" className=" py-2 text-xs font-semibold rounded-sm text-center text-primary border-[1px] border-primary flex items-center justify-center hover:bg-primary hover:text-white gap-2"><span>Checkout</span><FaArrowRightLong /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* side bar */}
            <div onClick={() => setShowSideBar(false)} className={`${(showSideBar ? ' opacity-100 visible' : ' opacity-0 invisible')} transition-all duration-500 md:hidden fixed z-30 top-0 left-0 w-full h-screen bg-secondary/20`}></div>
            <div className={`${(showSideBar ? ' opacity-100 visible left-0' : ' opacity-0 invisible -left-20')} transition-all duration-500 md:hidden fixed z-40 top-0 bottom-0 h-screen max-w-[80%] bg-tertiary text-white p-2`}>
                <div className=" relative min-w-[200px] w-full h-full overflow-y-auto scrollbar small-scrollbar">
                    <div onClick={() => setShowSideBar(false)} className=" absolute top-2 right-2 text-xl cursor-pointer hover:text-primary"><IoCloseOutline /></div>
                    <div className=" mt-14 mb-4 w-full px-4">
                        <form onSubmit={handleSearchSubmit} className=' w-full flex items-center'>
                            <input 
                                type="text" 
                                name="search" 
                                placeholder='Search product' 
                                className=' w-full h-[40px] rounded-l-sm rounded-r-none border-[1px] focus:border-primary'
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}  // Update searchValue state on change
                            />
                            <button className=' bg-primary text-white h-[40px] rounded-l-none rounded-r-sm' type="submit"><BsSearch /></button>
                        </form>
                    </div>
                    <div className=" px-4">
                        <div className=" text-primary font-semibold">CATEGORIES</div>
                        <div className="divide-y-[1px] divide-white/10">
      {categories.map((category) => (   
        <a
          key={category._id}
          href={`/search?category=${encodeURIComponent(category.name)}`}
          className="block py-2 hover:text-yellow-600 capitalize"
        >
          {category.name}
        </a>
      ))}
    </div>
                    </div>
                    <div className=" px-4 mt-4">
                        <div className=" flex items-center justify-center gap-4">
                            <a href="#/" className=" w-[40px] aspect-square rounded-full flex justify-center items-center border-[1px] hover:text-primary hover:border-primary"><FaFacebookF /></a>
                            <a href="#/" className=" w-[40px] aspect-square rounded-full flex justify-center items-center border-[1px] hover:text-primary hover:border-primary"><FaTwitter /></a>
                            <a href="#/" className=" w-[40px] aspect-square rounded-full flex justify-center items-center border-[1px] hover:text-primary hover:border-primary"><FaInstagram /></a>
                            <a href="#/" className=" w-[40px] aspect-square rounded-full flex justify-center items-center border-[1px] hover:text-primary hover:border-primary"><FaYoutube /></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Top;
