import { NavLink } from "react-router-dom"
import SideNav from "../SideNav"
import { RxDashboard } from "react-icons/rx";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineLocalGroceryStore, MdOutlineRateReview, MdOutlinePayments } from "react-icons/md";
import { RiHeartAddLine } from "react-icons/ri";
import { LuUserSquare } from "react-icons/lu";

const UserNav = ({ expandedNav, setExpandedNav }) => {

    return (
        <SideNav expandedNav={expandedNav} setExpandedNav={setExpandedNav}>
            <div className=" text-gray-500 font-semibold mb-3 mt-6 uppercase text-lg">MENU</div>
            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : '')} to={'/user/dashboard'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                    <RxDashboard />
                    <span>My Tripstore Account</span>
                </div>
            </NavLink>

            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : '')} to={'/user/order'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                <MdOutlineLocalGroceryStore />
                    <span>Orders</span>
                </div>
            </NavLink>

            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : '')} to={'/user/review'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                <MdOutlineRateReview />
                    <span>Reviews</span>
                </div>
            </NavLink>

            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : '')} to={'/user/zaddylist'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                <MdOutlinePayments />
                    <span>Zaddy pay</span>
                </div>
            </NavLink>

            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : '')} to={'/user/wishlist'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                <RiHeartAddLine />
                    <span>Saved Items</span>
                </div>
            </NavLink>

            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : '')} to={'/user/promoter'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                <LuUserSquare />
                    <span>Promoter</span>
                </div>
            </NavLink>

            <div className=" text-gray-500 font-semibold mb-3 mt-6 uppercase text-lg">Others</div>
            <NavLink className={({ isActive }) => (isActive ? ' text-primary' : '')} to={'/user/settings'}>
                <div className=" flex items-center gap-4 cursor-pointer hover:text-primary my-4">
                    <IoSettingsOutline />
                    <span>Settings</span>
                </div>
            </NavLink>
        </SideNav>
    )
}

export default UserNav