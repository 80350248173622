import React, { useState, useEffect } from 'react';
import Top from './top';
import Footer from './Footer';
import ScrollUpBtn from './ScrollUpBtn';
import { fetchPageContent } from '../calls/auths';

const Terms = () => {
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadContent = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetchPageContent('Terms and Conditions');
        if (response.status === 200 && response.data) {
          setContent(response.data.content || '');
        } else {
          setContent('');
          setError('No content Available.');
        }
      } catch (err) {
        console.error('Failed to load content:', err);
        setError('Failed to load content.');
      } finally {
        setIsLoading(false);
      }
    };

    loadContent();
  }, []);

  return (
    <div>
      <Top />
      <div className="text-left p-8">
        <h1 className="text-2xl font-semibold italic underline mb-5">Terms and Conditions</h1>
        {isLoading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        )}
      </div>
      <Footer />
      <ScrollUpBtn />
    </div>
  );
};

export default Terms;
