import { useState, useEffect, useRef, useContext } from "react";
import Logo from "../../assets/logo-bg.png";
import { TfiFullscreen } from "react-icons/tfi";
import { IoClose } from "react-icons/io5";
import { BsChevronLeft, BsChevronRight, BsChevronDown } from "react-icons/bs";
import { FaStar } from "react-icons/fa6";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { FaFacebookF, FaInstagram, FaPinterest, FaHeart } from "react-icons/fa";
import { IoLogoTwitter } from "react-icons/io";
import { AppContext } from "../../context/AppContext";
import SizeGuides from "./SizeGuide";

const DisplaySpec = ({
  setPrice,
  setDiscount,
  discount,
  setSelectedColor,
  outOfStock,
  addToCartFunction,
  colorId,
  setStockAmount,
  stockAmount,
  setAddToCartSuccessfully,
  selectedColor,
  currentSize,
  setCurrentSize,
  setProductVariantId,
  setColorId,
  removefromWishListFunction,
  addToWishListFunction,
  setIsInWishlist,
  isInWishlist,
  reviews,
  img = [Logo],
  title,
  rating = 0,
  reviewsNum = 0,
  price = 0,
  description = "",
  qty,
  setQty,
  category = "",
  sizes,
  color,
  handleAddToCart,
  handleAddToWishlist,
  product,
}) => {
    // console.log('Product Prop', product);
  const { user, wishlistItems } = useContext(AppContext);
  const [currentImg, setCurrentImg] = useState({ index: 0, img: img[0] });
  const [popImg, setPopImg] = useState(false);
  const [showSizeDropdown, setShowSizeDropdown] = useState(false);
  const [colors, setColors] = useState(sizes[0]);

  function selectTheColor(colorIndex) {
    setSelectedColor(colorIndex);
  }

  function shareToFacebook() {
    const currentUrl = window.location.href;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`, '_blank');
  }

  function shareToTwitter() {
    const currentUrl = window.location.href;
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`, '_blank');
  }


  function shareToInstagram() {
    const currentUrl = window.location.href;
    // Instagram does not support direct URL sharing, so we just copy the link
    navigator.clipboard.writeText(currentUrl).then(() => {
      alert('Invoice link copied to clipboard! Share it on Instagram.');
    }).catch(err => {
      console.error('Failed to copy: ', "err");
    });
  }


  useEffect(() => {
    if (product && wishlistItems) {
      const isProductInWishlist = wishlistItems.some(
        (item) => item.product === product._id
      );
      setIsInWishlist(isProductInWishlist);
    }
  }, [product, wishlistItems]);

  const zoomRef = useRef(null);
  const imageRef = useRef(null);

  const removeQty = () => {
    if (qty > 1) {
      setQty(qty - 1);
    }
  };

  const addQty = () => {
    setQty(qty + 1);
  };

  const handleShowSizeDropdown = (e) => {
    e.stopPropagation();
    setShowSizeDropdown(!showSizeDropdown);
  };

  const changeCurrentImg = (index) => {
    setCurrentImg({ index: index, img: img[index] });
  };

  const handleNext = () => {
    let i = currentImg.index + 1;
    if (i === (img.length < 4 ? img.length : 4)) {
      i = 0;
    }
    setCurrentImg({ index: i, img: img[i] });
  };

  const handlePrev = () => {
    let i = currentImg.index - 1;
    if (i < 0) {
      i = (img.length < 4 ? img.length : 4) - 1;
    }
    setCurrentImg({ index: i, img: img[i] });
  };

  const handleMouseMove = (e) => {
    const { offsetX, offsetY } = e.nativeEvent;
    const { offsetWidth, offsetHeight } = zoomRef.current;
    const xPercent = (offsetX / offsetWidth) * 100;
    const yPercent = (offsetY / offsetHeight) * 100;

    imageRef.current.style.transformOrigin = `${xPercent}% ${yPercent}%`;
    imageRef.current.style.transform = "scale(2)"; // Adjust the scale as needed
  };

  const handleMouseLeave = () => {
    imageRef.current.style.transform = "scale(1)";
  };

  useEffect(() => {
    window.onclick = () => setShowSizeDropdown(false);
  }, []);

  // Calculate average rating from reviews
  const calculateAverageRating = () => {
    const approvedReviews = reviews.filter((item) => item.is_approved);
    if (approvedReviews.length === 0) return 0;

    const totalRating = approvedReviews.reduce(
      (acc, review) => acc + review.rating,
      0
    );
    const averageRating = totalRating / approvedReviews.length;

    // Round up to nearest whole number
    return Math.ceil(averageRating);
  };

  return (
    <>
      <div className="container py-4">
        <div className="flex flex-col md:flex-row items-start gap-6 *:w-full">
          <div className="flex flex-col-reverse lg:flex-row gap-2">
            <div className="flex flex-row lg:flex-col gap-2">
              {img.slice(0, 4).map((item, index) => (
                <div
                  onClick={() => changeCurrentImg(index)}
                  key={index}
                  className={`${
                    currentImg.index === index
                      ? "border border-secondary border-l-4 border-b-2"
                      : "border-slate-300"
                  } w-[100px] h-[100px] border-[1px] cursor-pointer`}
                >
                  <img
                    src={item}
                    className={`${
                      currentImg.index === index ? "opacity-100" : "opacity-60"
                    } hover:opacity-100 transition w-full h-full object-contain`}
                    alt=""
                  />
                </div>
              ))}
            </div>
            <div
              className="relative flex-1 aspect-square bg-contain bg-center bg-no-repeat border-[1px] border-secondary rounded-sm zoomable-image-container"
              ref={zoomRef}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              // style={{ backgroundImage: `url(${currentImg.img})` }}
            >
              <img
                src={currentImg.img}
                className="zoomable-image w-full h-full object-contain"
                ref={imageRef}
                alt=""
              />
              <div
                onClick={() => setPopImg(true)}
                className="absolute bottom-4 right-4 w-[40px] aspect-square bg-white flex justify-center items-center rounded border-[1px] border-slate-300 text-gray-500 hover:bg-primary hover:text-white cursor-pointer"
              >
                <TfiFullscreen />
              </div>
            </div>
          </div>
          <div className="">
            <div className="text-xl font-semibold">{title}</div>
            <div className="mt-2 flex items-center gap-2">
              <div className="flex gap-1 items-center">
                {[...Array(calculateAverageRating())].map((_, index) => (
                  <div key={index} className="text-yellow-500">
                    <FaStar />
                  </div>
                ))}
                {[...Array(5 - calculateAverageRating())].map((_, index) => (
                  <div key={index} className="text-gray-400">
                    <FaStar />
                  </div>
                ))}
              </div>
              <div className="text-gray-400">
                ({reviews.filter((item) => item.is_approved).length} Review
                {reviewsNum > 1 ? "s" : ""})
              </div>
            </div>
            <div className="text-lg text-primary">
              {discount ? (
                <span className="text-secondary">
                  <span style={{ textDecoration: "line-through" }}>
                    &#8358;{price.toLocaleString()}
                  </span>{" "}
                  &#8358;{(price - price * (discount / 100)).toLocaleString()}
                  <p className="text-green-500">{discount}% OFF!</p>
                </span>
              ) : (
                <span className="text-secondary">
                  &#8358;{price.toLocaleString()}
                </span>
              )}

              {stockAmount !== null &&
                (outOfStock ? (
                  <p className="text-red-500">OUT OF STOCK!</p>
                ) : (
                  <p className="text-green-500">
                    {stockAmount} available in stock
                  </p>
                ))}
            </div>
            <div className="mt-4 text-gray-600">{description}</div>
            <div className="mt-4 flex gap-2 flex-wrap items-center">
              <div className={`${currentSize ? "block" : "hidden"}`}>
                Color:
              </div>
              <div className="flex space-x-2">
                {currentSize &&
                  colors.colors.map((color, index) => (
                    <div
                      onClick={() => {
                        selectTheColor(color.color);
                        setColorId(color._id);
                        setStockAmount(color.amount);
                      }}
                      key={color._id}
                      className={`${
                        color.color === selectedColor && !outOfStock
                          ? "border-secondary border-[3px]"
                          : ""
                      } w-10 h-10 rounded-full border border-gray hover:border-secondary cursor-pointer`}
                      style={{ backgroundColor: color.color }}
                    ></div>
                  ))}
              </div>
              <br />
            </div>
            <div className="mt-4 flex flex-col md:flex-row items-start md:items-center gap-2">
              <div className="">Size:</div>
              <div className="relative min-w-[200px]">
                <div
                  onClick={handleShowSizeDropdown}
                  className="cursor-pointer w-full py-1 px-3 border border-secondary border-l-4 border-b-2 rounded-sm text-gray-400 flex justify-between items-center gap-4"
                >
                  <span>
                    {currentSize === "" ? (
                      "Select a size"
                    ) : (
                      <span className="capitalize text-gray-600">
                        {currentSize}
                      </span>
                    )}
                  </span>
                  <span
                    className={`${
                      showSizeDropdown ? "rotate-180" : "rotate-0"
                    } transition duration-500`}
                  >
                    <BsChevronDown />
                  </span>
                </div>
                <div
                  className={`${
                    showSizeDropdown ? "block" : "hidden"
                  } absolute left-0 w-full mt-1 py-1 border border-secondary border-l-2 border-b-2 rounded-sm bg-white`}
                >
                  {sizes.map((size, index) => (
                    <div
                      onClick={() => {
                        setCurrentSize(size.size);
                        setPrice(size.price);
                        setDiscount(size.discount);
                        setColors(product.measurements[index]);
                        setColorId(null);
                        setProductVariantId(product.measurements[index]._id);
                      }}
                      key={index}
                      className="p-2 hover:bg-slate-200 cursor-pointer capitalize"
                    >
                      {size.size}
                    </div>
                  ))}
                </div>
                <div></div>
              </div>
              <SizeGuides
                category={product.category || ""}
                gender={product.gender || ""}
              />
            </div>
            <div className="mt-6 flex items-center gap-4">
              <div className="w-[120px] p-1 border border-secondary border-l-4 border-b-2 rounded-sm flex justify-between items-center gap-3 active:text-[12px]">
                <div
                  onClick={removeQty}
                  className="hover:text-primary active:text-[12px] w-[30px] aspect-square flex justify-center items-center cursor-pointer hover:border"
                  title="Reduce"
                >
                  -
                </div>
                <div className="w-[30px] aspect-square flex justify-center items-center border-x-[1px] border-slate-300">
                  {qty}
                </div>
                <div
                  onClick={addQty}
                  className="hover:text-primary active:text-[12px] w-[30px] aspect-square flex justify-center items-center cursor-pointer hover:border"
                  title="Add"
                >
                  +
                </div>
              </div>
              <div className="flex gap-3 items-center text-white">
                <button
                  onClick={() => addToCartFunction()}
                  className={`border border-secondary border-l-4 border-b-2 text-secondary active:text-[12px] py-2 px-4 flex justify-center items-center gap-2 text-sm ${
                    outOfStock ? "cursor-not-allowed opacity-50" : ""
                  }`}
                  disabled={outOfStock}
                  title={
                    outOfStock
                      ? `Select a color for this product`
                      : "Add to Cart"
                  }
                >
                  <span>
                    <MdOutlineAddShoppingCart />
                  </span>
                  <span>{colorId ? "Add to Cart" : "Select a color"}</span>
                </button>

                {isInWishlist ? (
                  <button
                    onClick={() => removefromWishListFunction(user, product)}
                    className="border border-secondary border-l-4 border-b-2 p-2 rounded-sm"
                  >
                    <FaHeart className="text-red-500" />
                  </button>
                ) : (
                  <button
                    onClick={() => addToWishListFunction(user, product)}
                    className="border border-secondary border-l-4 border-b-2 p-2 rounded-sm"
                  >
                    <FaHeart className="text-gray-500 hover:text-red-500" />
                  </button>
                )}
              </div>
            </div>
            <div className="mt-4 flex items-center gap-4">
              <div className="text-sm">Share:</div>
              <div className="flex gap-2 text-sm">
                <div className="w-[30px] aspect-square rounded-sm bg-slate-50 border border-secondary border-l-2 border-b-2 text-gray-500 flex justify-center items-center hover:bg-primary hover:text-white transition cursor-pointer" onClick={shareToFacebook}>
                  <FaFacebookF />
                </div>
                <div className="w-[30px] aspect-square rounded-sm bg-slate-50 border border-secondary border-l-2 border-b-2 text-gray-500 flex justify-center items-center hover:bg-primary hover:text-white transition cursor-pointer" onClick={shareToTwitter}>
                  <IoLogoTwitter />
                </div>
                <div className="w-[30px] aspect-square rounded-sm bg-slate-50 border border-secondary border-l-2 border-b-2 text-gray-500 flex justify-center items-center hover:bg-primary hover:text-white transition cursor-pointer" onClick={shareToInstagram}>
                  <FaInstagram />
                </div>
                <div className="w-[30px] aspect-square rounded-sm bg-slate-50 border border-secondary border-l-2 border-b-2 text-gray-500 flex justify-center items-center hover:bg-primary hover:text-white transition cursor-pointer">
                  <FaPinterest />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popImg && (
        <div className="fixed inset-0 w-full h-screen bg-secondary/50 z-20 p-4 overflow-hidden flex justify-center items-center">
          <div className="relative h-full flex justify-center items-center">
            <div
              onClick={() => setPopImg(false)}
              className="absolute top-4 right-4 w-[40px] aspect-square flex justify-center items-center rounded-full border-[1px] border-slate-200 text-white cursor-pointer z-50"
            >
              <IoClose />
            </div>
            <div
              className="relative h-[57vh] md:h-[80vh] aspect-square bg-cover bg-center bg-no-repeat rounded-sm"
              style={{ backgroundImage: `url(${currentImg.img})` }}
            >
              <div
                onClick={handlePrev}
                className="absolute left-0 top-1/2 -translate-y-1/2 h-full flex justify-center items-center px-4 text-white cursor-pointer z-50"
              >
                <BsChevronLeft />
              </div>
              <div
                onClick={handleNext}
                className="absolute right-0 top-1/2 -translate-y-1/2 h-full flex justify-center items-center px-4 text-white cursor-pointer z-50"
              >
                <BsChevronRight />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DisplaySpec;
