import { useContext, useState, useEffect } from "react";
import { HiOutlineLightBulb } from "react-icons/hi";
import { AppContext } from "../../context/AppContext";
import { getRandomStickyTopContent } from "../../calls/auths";

const StickyTop = () => {
    const { offset } = useContext(AppContext);
    const [content, setContent] = useState(null);

    const fetchRandomContent = async () => {
        const result = await getRandomStickyTopContent();
        if (result.status === 200) {
            setContent(result.data);
        } else {
            console.error("Failed to fetch random content:", result.data);
        }
    };

    useEffect(() => {
        fetchRandomContent();
    }, []);

    useEffect(() => {
        const handleNavigation = () => {
            fetchRandomContent();
        };

        window.addEventListener('popstate', handleNavigation);

        return () => {
            window.removeEventListener('popstate', handleNavigation);
        };
    }, []);

    if (!content) return null;

    return (
        <div className={`${(offset > 300) ? 'fixed top-0 shadow' : '-top-10'} transition-all duration-500 w-full z-40`} style={content ? { backgroundColor: content.bgColor, color: content.textColor } : {}}>
    <div className="app-container">
        {content ? (
            <a href={content.link}>
                <div className="flex flex-row items-center h-[80px]" style={{ color: content.textColor }}>
                    <h1 className="text-sm md:text-xl">{content.mainText}</h1>
                    <h1 className="text-sm md:text-xl px-0 md:px-5">{content.subText}</h1>
                    <div className="h-full flex-1 flex items-center">
                        <div className="w-full flex items-center gap-0 md:gap-2 justify-end px-2 md:px-4 md:border-l-[1px] md:border-secondary">
                            <HiOutlineLightBulb />
                            <span className="hidden md:block">{content.highlightText}</span>
                            <span className="font-semibold">{content.offerText}</span>
                        </div>
                    </div>
                </div>
            </a>
        ) : (
            <div className="flex flex-row items-center h-[80px] justify-center" style={{ color: 'gray' }}>
                <h1 className="text-sm md:text-xl">No content available</h1>
            </div>
        )}
    </div>
</div>
    );
};

export default StickyTop;