import React, { useState } from 'react'
import { promoterRegister } from '../calls/auths';
import Footer from './Footer';
import ScrollUpBtn from './ScrollUpBtn';
import { Navigate } from 'react-router-dom';
import Top from './top';

const Promoters = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiRes, setApiRes] = useState();
  const [apiMsg, setApiMsg] = useState();

  const [inputs, setInputs] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone_number: '',
    bankname: '',
    accountnumber: '',
    accountname: '',
    twitter: '',
    instagram: '',
    facebook: '',
    linkedin: '',
    tiktok: '',
    snapchat: '',
    promoter_id: Math.random().toString(36).substring(2, 10) + (Math.random() * 100000 | 0),
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs(values => ({ ...values, [name]: value }));
  };

const handleRegistration = async (e) => {
    const promoterData = {
      firstname: inputs.firstname,
      lastname: inputs.lastname,
      email: inputs.email,
      phone_number: inputs.phone_number,
      bankname: inputs.bankname,
      accountnumber: inputs.accountnumber,
      accountname: inputs.accountname,
      twitter: inputs.twitter,
      instagram: inputs.instagram,
      facebook: inputs.facebook,
      linkedin: inputs.linkedin,
      tiktok: inputs.tiktok,
      snapchat: inputs.snapchat,
      promoter_id: inputs.promoter_id,
      status: 'pending',
    };

    try {
      await promoterRegister(promoterData);
      Navigate('/verify-promoter');
    } catch (error) {
      console.error('Registration failed:', error);
    }
};

const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await handleRegistration(e); // Call the registration function
    setIsLoading(false);

    setTimeout(() => {
      setApiRes();
    }, 3000);
};

  return (
    <div>
      <Top />
      <div>
        <form onSubmit={handleSubmit}>
            <div className='flex flex-col items-center justify-center gap-10'>
          <div>
          <h1 className='text-center text-2xl font-semibold underline mb-5'>Personal Details</h1>
          <div className='flex flex-row items-center justify-center gap-4'>
          <div className='flex flex-col'>
            <label htmlFor="firstname">First Name</label>
            <input type="text" name="firstname" id="firstname" onChange={handleChange}/>
          </div>
          <div className='flex flex-col'>
            <label htmlFor="lastname">Last Name</label>
            <input type="text" name="lastname" id="lastname" onChange={handleChange}/>
          </div>
          <div className='flex flex-col'>
            <label htmlFor="email">Email</label>
            <input type="email" name="email" id="email" onChange={handleChange}/>
          </div>
          <div className='flex flex-col'>
            <label htmlFor="phone_number">Phone Number</label>
            <input type="tel" name="phone_number" id="phone_number" onChange={handleChange}/>
          </div>
          </div>
          </div>
          <div>
          <h1 className='text-center text-2xl font-semibold underline mb-5'>Account Details</h1>
          <div className='flex flex-row items-center justify-center gap-4'>
          <div className='flex flex-col'>
            <label htmlFor="bankname">Bank Name</label>
            <select name="bankname" id="bankname" onChange={(e) => {
              const bankName = e.target.value;
              const accountNumber = document.getElementById('accountnumber').value;
              const accountNameInput = document.getElementById('accountname');
              if (bankName && accountNumber) {
                fetch(`https://api.paystack.co/bank/resolve?account_number=${accountNumber}&bank_code=${bankName}&type=ngn&currency=NGN`, {
                  headers: {
                    Authorization: "Bearer sk_test_8f3baa6f4d7f0b6d4d6e5f7c2b3a1b2a3c4d5e6f7g8h9i0j1k2l3m4n5o6p7q8r9s0t1u2v3w4x5y6z",
                  },
                })
                  .then(res => res.json())
                  .then(data => {
                    if (data.status === true) {
                      accountNameInput.value = data.data.account_name;
                    } else {
                      accountNameInput.value = '';
                    }
                  })
                  .catch(err => console.error(err));
              }
            }}>
              <option value="">Select Bank</option>
              <option value="Access Bank">Access Bank</option>
              <option value="ALAT by Wema">ALAT by Wema</option>
              <option value="Asharami Synergy Frontiers">Asharami Synergy Frontiers</option>
              <option value="Banco Wallet">Banco Wallet</option>
              <option value="Bank of Industry">Bank of Industry</option>
              <option value="Carbon">Carbon</option>
              <option value="Celullant">Celullant</option>
              <option value="Citibank Nigeria">Citibank Nigeria</option>
              <option value="Coronation Merchant Bank">Coronation Merchant Bank</option>
              <option value="EcoBank">EcoBank</option>
              <option value="Eyowo">Eyowo</option>
              <option value="FBNQuest Merchant Bank">FBNQuest Merchant Bank</option>
              <option value="FCMB">FCMB</option>
              <option value="Fidelity Bank">Fidelity Bank</option>
              <option value="First Bank of Nigeria">First Bank of Nigeria</option>
              <option value="First City Monument Bank">First City Monument Bank</option>
              <option value="Globus Bank">Globus Bank</option>
              <option value="Guaranty Trust Bank">Guaranty Trust Bank</option>
              <option value="Heritage Bank">Heritage Bank</option>
              <option value="Keystone Bank">Keystone Bank</option>
              <option value="Kuda">Kuda</option>
              <option value="Mintyn Bank">Mintyn Bank</option>
              <option value="Moniepoint Microfinance Bank">Moniepoint Microfinance Bank</option>
              <option value="NPF Microfinance Bank">NPF Microfinance Bank</option>
              <option value="OneFi">OneFi</option>
              <option value="Paga">Paga</option>
              <option value="PalmPay">PalmPay</option>
              <option value="Paycom">Paycom</option>
              <option value="Paystack">Paystack</option>
              <option value="Polaris Bank">Polaris Bank</option>
              <option value="Rand Merchant Bank">Rand Merchant Bank</option>
              <option value="Rubies Bank">Rubies Bank</option>
              <option value="Spectra">Spectra</option>
              <option value="Stanbic IBTC Bank">Stanbic IBTC Bank</option>
              <option value="Sterling Bank">Sterling Bank</option>
              <option value="Tangerine">Tangerine</option>
              <option value="Titan Trust Bank">Titan Trust Bank</option>
              <option value="UBA">UBA</option>
              <option value="Union Bank">Union Bank</option>
              <option value="Unity Bank">Unity Bank</option>
              <option value="VFD Microfinance Bank">VFD Microfinance Bank</option>
              <option value="Wema Bank">Wema Bank</option>
              <option value="Zenith Bank">Zenith Bank</option>
            </select>
          </div>
          <div className='flex flex-col'>
            <label htmlFor="accountnumber">Account Number</label>
            <input type="text" name="accountnumber" id="accountnumber" onChange={(e) => {
              const accountNumber = e.target.value;
              const bankName = document.getElementById('bankname').value;
              const accountNameInput = document.getElementById('accountname');
              if (bankName && accountNumber.length === 10) {
                fetch(`https://api.paystack.co/bank/resolve?account_number=${accountNumber}&bank_code=${bankName}&type=ngn&currency=NGN`, {
                  headers: {
                    Authorization: "Bearer sk_test_8f3baa6f4d7f0b6d4d6e5f7c2b3a1b2a3c4d5e6f7g8h9i0j1k2l3m4n5o6p7q8r9s0t1u2v3w4x5y6z",
                  },
                })
                  .then(res => res.json())
                  .then(data => {
                    if (data.status === true) {
                      accountNameInput.value = data.data.account_name;
                    } else {
                      accountNameInput.value = '';
                    }
                  })
                  .catch(err => console.error(err));
              }
            }} />
          </div>
          <div className='flex flex-col'>
            <label htmlFor="accountname">Account Name</label>
            <input type="text" name="accountname" id="accountname"  onChange={handleChange}/>
          </div>          </div>
          </div>
          <div>
          <h1 className='text-center text-2xl font-semibold underline mb-5'>Social Media Handles</h1>
          <div className='flex flex-row items-center justify-center gap-4'>
          <div className='flex flex-col'>
            <label htmlFor="twitter">Twitter</label>
            <input type="text" name="twitter" id="twitter" />
          </div>
          <div className='flex flex-col'>
            <label htmlFor="instagram">Instagram</label>
            <input type="text" name="instagram" id="instagram" />
          </div>
          <div className='flex flex-col'>
            <label htmlFor="facebook">Facebook</label>
            <input type="text" name="facebook" id="facebook" />
          </div>
          <div className='flex flex-col'>
            <label htmlFor="linkedin">Linkedin</label>
            <input type="text" name="linkedin" id="linkedin" />
          </div>
          <div className='flex flex-col'>
            <label htmlFor='tiktok'>Tiktok</label>
            <input type="text" name="tiktok" id="tiktok" />
          </div>
          <div className='flex flex-col'>
            <label htmlFor='snapchat'>Snapchat</label>
            <input type="text" name="snapchat" id="snapchat" />
          </div>
          </div>
          </div>
          <div>
            <button className='bg-primary text-white py-2 px-4 rounded mb-5' type="submit" disabled={isLoading}>Register</button>
          </div>
          </div>
        </form>
      </div>
            <Footer />
            <ScrollUpBtn />
    </div>
  )
}

export default Promoters
